.youtube
	#main
		width: 100%

	.youtube-player
		position: relative
		padding-bottom: 56.25%
		height: 0
		overflow: hidden
		max-width: 100%

		iframe
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			z-index: 100
			background: transparent

		img
			object-fit: cover
			display: block
			left: 0
			bottom: 0
			margin: auto
			max-width: 100%
			width: 100%
			position: absolute
			right: 0
			top: 0
			border: none
			height: auto
			cursor: pointer
			-webkit-transition: 0.4s all
			-moz-transition: 0.4s all
			transition: 0.4s all

			&:hover
				filter: brightness(75%)

		.play
			left: 50%
			top: 50%
			margin-left: -36px
			margin-top: -36px
			position: absolute
			cursor: pointer
			width: 91px
			height: 91px
			transition: background-color 100ms linear
			border-radius: 50px
			opacity: .8
			background-color: #fff
			box-shadow: 0 0 10px 0 rgba(black, 0.5)

		.playbutton
			content: ""
			position: absolute
			left: 50%
			top: 50%
			margin-left: -2px
			margin-top: -5px
			z-index: 99
			width: 30px
			height: 30px

			background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 500 500' enable-background='new 0 0 500 500' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23C70282' d='M35.6,6c16.8,10.8,33.5,21.7,50.3,32.4c109.4,69.6,218.8,139.2,328.2,208.8c1.2,0.8,2.3,1.6,4.1,2.8 c-2.3,1.5-4.2,2.7-6.2,4C288,333,163.8,412,39.7,491c-1.4,0.9-2.8,2-4.2,3c-0.3,0-0.6,0-1,0c0.1-1.1,0.2-2.2,0.2-3.3 c0-160.5,0-320.9,0-481.4c0-1.1-0.1-2.2-0.2-3.3C35,6,35.3,6,35.6,6z'/%3E%3C/g%3E%3C/svg%3E")

#botschafterin
	h1
		font-family: $regular
		font-size: $l
		margin-bottom: 2rem

		@media (min-width: $desktop)
			font-size: 3.5rem

	h2
		font-size: $l
		font-family: $regular
		max-width: 50rem
		line-height: 1.4
		margin-bottom: 1rem
		@media (min-width: $tablet)
			font-size: $xl

#interview
	&.padding--section
		@media (min-width: $desktop)
			padding: 10rem 2rem

	h2
		font-family: $regular
		font-size: $l
		color: #ffffff

		@media (min-width: $desktop)
			font-size: 3.5rem

	h3
		font-size: $l
		font-family: $regular
		line-height: 1.4
		margin-bottom: 1rem
		color: #ffffff
		max-width: 46rem
		@media (min-width: $tablet)
			font-size: $xl

	p
		color: #ffffff

#premiere
	position: relative
	background: white

	h3
		max-width: 25ch

	#gewinnspiel
		background: url('../img/ot_bg_cubes.jpg') center no-repeat
		background-size: cover
		padding: 2rem

	.right--content
		display: flex
		align-items: center

#brainhearing
	padding-bottom: 0
	padding-top: 0

	.two--col
		@media (min-width: $mobile)
			display: block
		@media (min-width: $tablet)
			display: grid
	// small
	//	 margin-top: 3rem
	//	 display: block
	.align--center
		align-items: stretch

	.left--content
		.container
			@media (min-width: $tablet)
				padding: 6rem 2rem

	.right--content
		position: relative
		padding: 2rem
		padding-bottom: 0

		img
			max-width: 60vw
			margin: 0 auto

		@media (min-width: $tablet)
			padding: 0
			img
				max-width: 100%
				margin: 0
				position: absolute
				left: 0
				bottom: 0

#more
	padding: 2rem
	background-color: #fff
	@media (min-width: $tablet)
		padding: 5rem 2rem

	.mobile--grafik
		width: 30rem
		margin: 2rem auto
		@media (min-width: 300px)
			display: none

	.tablet--grafik
		display: none
		width: 50rem
		margin: 2rem 0
		@media (min-width: 300px)
			display: block
		@media (min-width: $desktop)
			display: none

	.desktop--grafik
		display: none
		@media (min-width: $desktop)
			display: block

#youtube-einzeln
	&.padding--section
		@media (min-width: $desktop)
			padding: 10rem 2rem

	h2
		font-family: $regular
		font-size: $l
		margin-bottom: 2rem

		@media (min-width: $desktop)
			font-size: 3.5rem

	.two--col
		display: block

	@media (min-width: $tablet)
		.desktop
			display: none
		.two--col
			display: block
	@media (min-width: $desktop)
		.two--col
			display: grid

			align-items: center

.youtube-alle
	&.padding--section
		@media (min-width: $desktop)
			padding: 10rem 2rem 10rem

	.two--col
		display: block
		@media (max-width: 999px)
			display: flex !important
			flex-direction: column !important

	@media (min-width: $tablet)
		.desktop
			display: none
		.two--col
			display: block
	@media (min-width: $desktop)
		.two--col
			display: grid
			align-items: center

@media (max-width: 999px)
	.y1,
	.y3,
	.y5
		.left--content
			order: 2
		.right--content
			order: 1

#hoersysteme
	.desktop
		display: none
		width: 100%

	.two--col
		display: block

	@media (min-width: $tablet)
		.desktop
			display: none
		.two--col
			display: block
	@media (min-width: $desktop)
		.two--col
			display: grid

			align-items: center

	.neural
		background: url('../img/ot-neural.jpg') right center no-repeat
		background-size: cover
		min-height: 50vw
		@media (min-width: $desktop)
			.desktop
				display: block

			display: flex
			align-items: center
			justify-content: center
			background: none
	// min-height: 685px
	.icons
		padding: 2rem

		h4
			margin-bottom: 4rem

		.main--button
			margin-top: 3rem

#more-infos
	&.padding--section
		padding: 2rem
		@media (min-width: $desktop)
			padding: 4rem 2rem 10rem
	.container
		padding: 6rem 4rem

	p
		max-width: 40rem
		margin-bottom: 4rem
		font-size: 2rem

	.left--content,
	.right--content
		border: 4px solid $deep-magenta

	.right--content
		margin-top: 2rem
		@media (min-width: $mobile)
			margin-top: 0

#more-testimonial
	&.padding--section
		padding: 2rem
		@media (min-width: $desktop)
			padding: 10rem 2rem 10rem

	p
		max-width: 40rem
		margin-bottom: 4rem
		font-size: 2rem

	.container
		padding: 6rem 4rem
		border: 4px solid $deep-magenta
		max-width: 800px
		margin: auto

#more-euha
	&.padding--section
		@media (min-width: $desktop)
			padding: 10rem 2rem 10rem

	.content
		border: 4px solid $deep-magenta
		padding: 0

	.two--col
		display: block

	@media (min-width: $tablet)
		.two--col
			display: block
	@media (min-width: $desktop)
		.two--col
			display: grid
			align-items: center

#akku
	padding: 5rem 2rem
	// padding-bottom: 4rem
	h4
		font-family: $regular

		&.mt
			max-width: 100%
			margin-top: 6rem

	.right--content
		h4
			margin-top: 4rem
			@media (min-width: $mobile)
				margin-top: 0

	#streaming
		margin-top: 2rem

	strong
		display: block

	#more--geraet
		margin: 2rem auto

	#more--akku
		margin: 2rem auto

	.farbpalette
		margin: 4rem 0 2rem
		display: flex
		align-items: flex-start
		flex-wrap: wrap

		.geraet
			flex: 1 0 calc(100% / 2 - 2rem)
			-ms-flex: 1 0 45%
			text-align: center
			margin-bottom: 2rem
			@media (min-width: $mobile)
				flex: 1 0 calc(100% / 4 - 4rem)
				-ms-flex: 1 0 18%
			@media (min-width: $desktop)
				flex: 1 0 calc(100% / 8 - 2rem)
				-ms-flex: 1 0 10%

			p
				position: relative
				transform: translateX(-12%)

			strong
				margin-top: 2rem
				text-transform: uppercase

			margin-right: 2rem

			&:nth-child(2n)
				margin-right: 0

			@media (min-width: $mobile)
				margin-right: 4rem !important
				&:nth-child(4n)
					margin-right: 0 !important
			@media (min-width: $desktop)
				margin-right: 2rem !important
				&:nth-child(4n)
					margin-right: 2rem !important
				&:last-child
					margin-right: 0 !important

#euhaGewinnspiel
	&.padding--section
		@media (min-width: $desktop)
			padding: 0 2rem
			z-index: 999
.youtube__content
	max-width: 100rem
	text-align: center
	padding: 0 2rem
	.youtube-player
		margin-top: 2rem
	.video__thumbnails
		margin: 2rem 0
		@include grid-areas('g1 g2' 'g3 g4' 'g5 g6', 1fr 1fr, auto, 1rem)
		@include tablet
			@include grid-areas('g1 g2 g3' 'g4 g5 g6', 1fr 1fr 1fr, auto, 2rem)
		@for $i from 1 through 5
			.video
				cursor: pointer
				border: 2px solid white
				&.active
					border: 2px solid $magenta
				&:nth-child(#{$i})
					grid-area: g#{$i}

		

.soft--row-ot
	display: flex
	flex-wrap: wrap
	justify-content: center
	align-items: flex-start
	@include desktop
		align-items: center
	.content
		flex: 1 0 100%
		order: 2
	.content__image
		order: 1
		flex: 1 0 100%
		padding: 1rem
		img
			width: 25rem
			margin: 0 auto
	@include desktop
		.content
			order: 1
			flex: 1 0 60%
		.content__image
			order: 2
			flex: 1 0 40%
			padding: 1rem
			img
				max-width: 100%