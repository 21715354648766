#login
    position: relative
    &:before
        content: ""
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        background: rgba(black, 0.8)
    .content
        display: flex
        align-items: center
        flex-direction: column
        justify-content: center
        h1
            margin-top: 0
form
    background: white
    padding: 2rem
    margin-top: 2rem
    width: 100%
    max-width: 40rem
    box-shadow: 0 0 1rem rgba(black, 0.2)
    *
        font-size: 1.6rem
    input[type="text"]
        padding: 0.5rem 1rem
        display: block
        margin-bottom: 1rem
        width: 100%
    .checkbox--group
        margin: 2rem 0
    .error
        margin-top: 2rem
        color: red
