.modal__winterglueck
	position: fixed
	left: 0
	top: 0
	width: 100%
	height: 100%
	background: url('../img/purple_bg.jpg') center no-repeat
	background-size: cover
	z-index: 99
	padding: 2rem
	align-items: center
	justify-content: center
	display: none
	&.active
		display: flex

	.banner__winterglueck
		background: white
		padding: 4rem
		width: 100%
		max-width: 50rem
		position: relative
		transform: rotate(-3deg)
		box-shadow: -0.5rem 0.5rem 3rem rgba(black, 0.45)
		text-align: center
		z-index: 0
		
		&:after
			content: ""
			position: absolute
			left: 0.5rem
			top: 0.5rem
			right: 0.5rem
			bottom: 0.5rem
			border: 2px solid black
			pointer-events: none
		p
			margin-bottom: 2rem
		.main--button
			cursor: pointer
