.uc-powered-by-footer
    display: none!important


.uc-btn-footer-container
    display: block!important
.btn-list
    float: none!important
#uc-btn-more-info-banner
    font-size: 90%!important

#usercentrics-button .uc-btn-new
    margin: 0!important

.uc-info-modal-sidebar-nav
    background: #efefef
.uc-powered-by
    display: none!important

.uc-embedding-wrapper
    background: #333!important