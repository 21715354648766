.two--col
    display: block
    h3
        margin-bottom: 1rem
    @media (min-width: $mobile)
        display: grid
        grid-template-areas: "left right"
        grid-template-columns: 1fr 1fr
        grid-template-rows: auto
        grid-gap: 3rem
.left--content
    grid-area: left
.right--content
    grid-area: right
.scrollEvent
    .left--in
        transform: translateX(-60%)
        opacity: 0
        transition: tranform 800ms ease, opacity 800ms ease
    .right--in
        transform: translateX(60%)
        opacity: 0
        transition: transform 800ms ease, opacity 800ms ease
    &.INTERSECTING
        .right--in, .left--in
            transform: translateX(0)
            opacity: 1
.two--one
    @media (min-width: $desktop)
        grid-template-columns: 2fr 1fr
        -ms-grid-columns: 2fr 3rem 1fr
.container
    padding: 2rem

.padding--section
    background: white
    position: relative
    z-index: 1
    // padding: 2rem
    @media (min-width: $desktop)
        padding: 5rem 2rem

.align--center
    align-items: center


.lightgrey
    background: lighten($hellgrau, 15%)
.deep-magenta
    background-color: #410032

.lines
    background: url('../img/bg-raster.png') left top repeat
    background-size: 3rem 3rem

.no--margin
    margin-bottom: 1rem

.mw--70
    max-width: 70rem

.mt
    margin-top: 2rem

.desktop
    display: none
    @media (min-width: $mobile)
        display: block
.mobile
    @media (min-width: $mobile)
        display: none

.icon--row
    display: flex
    margin-bottom: 3rem
    align-items: flex-start
    @media (min-width: $mobile)
        align-items: center
    strong
        display: block
    img
        margin-right: 2rem
        max-width: 60px
        min-width: 60px
        width: 60px
        @media (min-width: $mobile)
            max-width: 100px
            min-width: 100px
            width: 100px

ul
    li
        margin-left: 2rem
        margin-top: 0.5rem
.wintercontent
    max-width: 95rem!important