.main--button
	display: inline-block
	font-family: $bold
	border: 4px solid $deep-magenta
	padding: 0.7rem 2rem
	transition: all 300ms ease
	text-align: center

	&.main__button-white
		border-color: white
		color: white
		text-align: center!important
	&:hover
		border-color: $magenta
		color: $magenta