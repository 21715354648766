#quiz-header
	background-image: url('../img/header-quiz.jpg')
	background-position: top center
	background-attachment: initial
	.anchor--link
		display: none
		@media (min-width: $tablet-large)
			display: block
	@media (min-width: $mobile)
		background-attachment: fixed
	position: relative
	&:before
		content: none
		position: absolute
		left: 0
		top: 0
		bottom: 0
		width: 90%
		background: linear-gradient(to right, rgba(black, 1), rgba(black, 0))
	.content
		display: flex
		align-items: flex-end
		@media (min-width: $tablet-large)
			align-items: center
	.container
		margin-top: 4rem
		background: rgba(white, 0.8)
		@media (min-width: $tablet-large)
			width: 50%
		h1
			font-size: 2.4rem
			@media (min-width: $tablet-large)
				font-size: 3.4rem
			margin: 0
			color: black
			text-shadow: none
			margin-bottom: 2rem
		*
			// color: white
#quiz
	h2
		font-size: 2rem
		margin-bottom: 2rem
		@media (min-width: $mobile)
			font-size: 4rem
	h2, p
		max-width: 90rem

#quiz--content
	margin-top: 2rem
	.two--col
		@media (max-width: $tablet)
			display: block
	.left--col,
	.right--col
		padding: 2rem
	.current--question
		// @media (min-width: $mobile)
		//	 display: flex
		//	 align-items: center
		h3
			flex-grow: 1
			min-width: 80%
			margin-bottom: 10px
			font-size: 1.8rem
			@media (min-width: $mobile)
				margin-bottom: 20px
				font-size: 2.4rem
	.answer--content
		flex-grow: 1
		@media (min-width: $mobile)
			display: flex
			flex-wrap: wrap
			align-items: center
		.error--message
			width: 80%
			font-size: 1.6rem
			color: $magenta
		.questions--container
			margin: 20px 0
			flex-grow: 1
			min-width: 80%
			display: block
			flex-wrap: wrap
			@media (min-width: $mobile)
				margin-right: -20px
			// align-items: center
			.game--button  
				@extend .main--button
				display: flex
				align-items: center
				flex: 1 0 80% 
				// padding: 10px 20px
				// border: 1px solid #dadada
				margin-bottom: 10px
				font-size: 1.4rem
				@media (min-width: $mobile)
					flex: 1 0 45%
					margin-right: 20px
					font-size: 18px
					margin-bottom: 20px
				cursor: pointer
			input[type="radio"]
				display: none
			input[data-checked="true"]
				+ .game--button
					border-color: $magenta

	.progress--bar
		width: auto
		text-align: center
		background: white
		display: flex
		flex-direction: row-reverse
		box-shadow: 0 0 1rem rgba(black, 0.2)
		@media (min-width: $mobile)
			display: flex
		div
			// margin-bottom: 1px
			font-size: 1rem
			flex-grow: 1 
			color: transparent
			height: 100%
			padding: 5px 0px
			@media (min-width: $mobile)
				color: black
				padding: 5px 10px
				font-size: 1.6rem
			&.active
				background: $magenta
				@media (min-width: $mobile)
					color: white
	form#quiz--form
		width: 100%
		max-width: 100%
		h4
			color: $magenta
			font-size: 3rem
			margin-bottom: 0
		input[type="text"],
		input[type="email"]
			width: 100%
			padding: 0.5rem 1rem
			background: white
			border: 1px solid #c3c3c3
			margin-bottom: 1rem
		button
			cursor: pointer
		.toggle--group
			display: flex
			cursor: pointer
			@media (min-width: $tablet)
				align-items: center
			margin-bottom: 20px
			.toggle--switch
				height: 2.5rem
				width: 4rem
				min-width: 4rem
				background: #c3c3c3
				border-radius: 3rem
				margin-right: 1rem
				position: relative
				transition: all 150ms ease
				&:before
					content: ""
					position: absolute
					left: 2px
					top: 2px
					width: 2.1rem
					height: 2.1rem
					background: #3B3C3D
					border-radius: 50%
					transition: all 150ms ease
			p
				display: block
				font-size: 1.2rem
				a
					font-size: inherit
					text-decoration: underline

			input
				display: none
				&:checked
					~ .toggle--switch
						background: #C70282
						&:before
							transform: translateX(1.5rem)
							background: white
		small
			margin: 0 0 10px
			font-size: 1.2rem
			color: #C70282

.centered--button
	display: inline-block
	margin: 0 auto
#solution
	padding: 6rem 2rem
	.link
		margin-top: 1rem
		display: inline-flex
	a:not(.link):not(.main--button)
		font-weight: bold
		text-decoration: underline

#gewinne
	background: url('../img/ot_bg_cubes.jpg') center center no-repeat
	background-size: cover
	position: relative
	&:before
		content: ""
		position: absolute
		left: 0
		top: 0
		height: 50%
		width: 100%
		background: linear-gradient(to bottom, rgba(black, 0.5), rgba(black, 0))
	@media (min-width: $tablet)
		&:before
			height: 100%
			width: 50%
			background: linear-gradient(to right, rgba(black, 0.5), rgba(black, 0))
	h3
		max-width: 30ch
		font-size: 2.4rem
		@media (min-width: $tablet-large)
			font-size: 3.4rem
		margin: 0
		color: white
		text-shadow: none
		margin-bottom: 2rem
	*
		position: relative
		color: white
	.right--content
		padding: 0 0 2rem 2rem
		@media (min-width: $tablet)
			padding: 8rem 0



//=================================================
// CUBE
//=================================================

$cubesize-s: 65vw
$cubehalfsize-s: calc($cubesize-s / 2)

$cubesize: 35vw
$cubehalfsize: calc($cubesize / 2)

$cubesize-l: 40rem
$cubehalfsize-l: calc($cubesize-l / 2)


.cube--3D
	margin: 4rem 0
	@media (min-width: $tablet)
		margin: 0

	#D3Cube 
		transform-style: preserve-3d
		margin: auto
		position: relative
		transition: transform 0.5s ease-in-out
		width: $cubesize-s
		height: $cubesize-s
		@media (min-width: $tablet)
			width: $cubesize
			height: $cubesize
		@media (min-width: $desktop)
			width: $cubesize-l
			height: $cubesize-l

		> div
			position: absolute
			transition: background-color 0.3s ease-in-out
			width: $cubesize-s
			height: $cubesize-s
			@media (min-width: $tablet)
				width: $cubesize
				height: $cubesize
			@media (min-width: $desktop)
				width: $cubesize-l
				height: $cubesize-l
			float: left
			overflow: hidden
			opacity: 0.75
			background-color: $magenta
			display: flex
			align-items: center
			justify-content: center
			color: white
			font-size: 2rem
			span
				padding: 2rem
				text-align: center
				opacity: 0
				display: flex
				align-items: center
				justify-content: center
				font-weight: bold
				transition: opacity 300ms ease
				position: absolute
				left: 0
				top: 0
				width: 100%
				height: 100%
				font-size: 1.6rem
				@media (max-width: 360px)
					font-size: 1.4rem
				@media (min-width: $desktop)
					font-size: 2rem
			&:after
				content: ""
				position: absolute
				left: 0
				top: 0
				bottom: 0
				right: 0
				box-shadow: inset 0 0 10px 5px rgba(lighten($magenta, 40%), 0.2)

		#side1
			transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-s)
			background-color: $oben
			@media (min-width: $tablet)
				transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize)
			@media (min-width: $desktop)
				transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-l)
		
		#side2 
			transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-s)
			@media (min-width: $tablet)
				transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize)
			@media (min-width: $desktop)
				transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-l)

		#side3 
			transform: translateX(0px) translateY(0px) translateZ($cubehalfsize-s)
			@media (min-width: $tablet)
				transform: translateX(0px) translateY(0px) translateZ($cubehalfsize)
			@media (min-width: $desktop)
				transform: translateX(0px) translateY(0px) translateZ($cubehalfsize-l)

		#side4
			transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-s)   
			@media (min-width: $tablet)
				transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize)   
			@media (min-width: $desktop)
				transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-l)   

		#side5 
			transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-s)
			@media (min-width: $tablet)
				transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ($cubehalfsize)
			@media (min-width: $desktop)
				transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-l)


		#side6
			transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-s)
			background-color: $unten
			box-shadow: 0 0 120px rgba(black, 0.5), 0 0 20px rgba(black, 0.5)
			@media (min-width: $tablet)
				transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize)
			@media (min-width: $desktop)
				transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ($cubehalfsize-l)


		&.seite1
			transform: rotateX(-15deg) rotateY(115deg) rotateZ(0deg)
			#s5
				display: none
			#side2
				background-color: $magenta
				span
					opacity: 1
			#side5
				background-color: $links
			#side3
				background-color: $rechts
			#side4
				background-color: $hinten


		&.seite2
			transform: rotateX(-15deg) rotateY(25deg) rotateZ(0deg)
			#s6
				display: none
			#side3
				background-color: $magenta
				span
					opacity: 1
			#side2
				background-color: $links
			#side4
				background-color: $rechts
			#side5
				background-color: $hinten


		&.seite3
			transform: rotateX(-15deg) rotateY(-65deg) rotateZ(0deg)
			#s7
				display: none
			#side4
				span
					opacity: 1
			#side5
				background-color: $rechts
			#side3
				background-color: $links
			#side2
				background-color: $hinten
				
			
		&.seite4
			transform: rotateX(-15deg) rotateY(-155deg) rotateZ(0deg)
			#side5
				background-color: $magenta
				span
					opacity: 1
			#side4
				background-color: $links
			#side2
				background-color: $rechts
			#side3
				background-color: $hinten
		
	
		&.seite5
			transform: rotateX(-15deg) rotateY(-245deg) rotateZ(0deg)
			#s1
				display: none
			#side2
				background-color: $magenta
				span
					opacity: 1
			#side5
				background-color: $links
			#side3
				background-color: $rechts
			#side4
				background-color: $hinten

				
		&.seite6
			transform: rotateX(-15deg) rotateY(-335deg) rotateZ(0deg)
			#s2
				display: none
			#side3
				background-color: $magenta
				span
					opacity: 1
			#side2
				background-color: $links
			#side4
				background-color: $rechts
			#side5
				background-color: $hinten

		&.seite7
			transform: rotateX(-15deg) rotateY(-425deg) rotateZ(0deg)
			#s3
				display: none
			#side4
				span
					opacity: 1
			#side5
				background-color: $rechts
			#side3
				background-color: $links
			#side2
				background-color: $hinten

#gewinnspiel
	position: relative
	margin: 0 auto
	width: 100%
	max-width: 100%
	background-color: transparent
	box-shadow: unset
	//box-shadow: 0 0 10px rgba(black, 0.15)
	.gewinnspielContent
		display: flex
		overflow: hidden
		height: calc(100% - 51px)
	.soft-row
		display: flex
		min-width: 100%
		.col-1
			text-align: center
			width: 100%
			label[for="accept"]
				display: flex
				align-items: flex-start
				.customCheckbox
					position: relative
					left: 0
			.sternchentext
				color: white
				font-size: 12px
				margin-top: 30px
				strong
					font-size: inherit

			.customCheckbox
				top: 2px
				width: 15px
				height: 15px
				background: $bright-grey
				border: 1px solid lighten($light-grey, 20%)
				display: inline-block
				margin-right: 7px
				position: relative
				margin-top: 4px
			.allquestions
				display: inline-block
				text-align: left
				max-width: 60rem
				margin: 0 auto

				.inputGroup
					display: block

			.inputGroupForm
				margin: 0
				input
					appearance: none
					border-radius: none
					width: 100%
					padding: 10px
					border: 1px solid $light-grey
					background: $bright-grey
					outline: none
					margin-bottom: 20px

			input[type="radio"], input[type="checkbox"]
				display: none
				position: absolute
				left: -100000px

				&:checked~.customCheckbox
					&:before
						content: ""
						position: absolute
						background: $magenta
						width: 7px
						height: 3px
						transform: rotate(45deg)
						left: 2.5px
						bottom: 5px
					&:after
						content: ""
						position: absolute
						background: $magenta
						width: 12px
						height: 3px
						transform: rotate(-45deg)
						left: 5px
						bottom: 7px
			label
				font-family: $regular
				font-size: 2rem
				color: #ffffff
				display: block
				cursor: pointer
				line-height: 20px
				position: relative
				margin: 25px auto
				text-align: left
				@media (min-width: $desktop)
					font-size: 2.5rem
				.customCheckbox
					width: 20px
					height: 20px
					@media (min-width: $desktop)
						position: absolute
						left: -35px
						top: -5px
			.question
				font-size: 2.5rem
				font-family: $regular
				color: #ffffff
				margin: 20px auto
				max-width: 60rem
				line-height: 1.3
				@media (min-width: $desktop)
					font-size: 3.5rem

	.btns
		padding: 20px
		z-index: 99999999999
		.next
			background: #ffffff
			color: #000000
			padding: 20px
			border: none
			outline: none
			width: 200px
			font-size: 2rem
			font-family: $regular
			cursor: pointer
			display: inline-block
			text-align: center
		.prev
			background: $light-grey
			color: white
			margin-top: 30px
			padding: 20px
			border: none
			outline: none
			width: 200px
			font-size: 2rem
			font-family: $regular
			margin-bottom: 2rem
			@media (min-width: $desktop)
				margin-right: 10px
				margin-bottom: 0
			cursor: pointer
			display: inline-block
			text-align: center
		input[type="submit"]
			appearance: none
			background: #ffffff
			color: #000000
			padding: 20px
			border: none
			outline: none
			width: 200px
			font-size: 2rem
			font-family: $regular
			cursor: pointer
			border-radius: 0

	.frage
		transition: margin 400ms ease
		.col-1
			&:first-child
				display: flex
				justify-content: center
				flex-direction: column

	.quizForm
		height: 0
		transition: all 400ms ease
		h2
			color: white
			font-size: 1.4rem
			font-family: $regular
			margin-bottom: 30px
		label
			max-width: 100%
			line-height: 1.3
			@media (min-width: $desktop)
				margin-left: 35px
			font-size: 2rem
		&.active
			height: auto
		.col-1

			&:last-child
				.soft-row
					align-items: center
					margin-bottom: 20px
					.col-1
						background: none
						padding: 0
						text-align: center

						p
							color: white
							font-size: 1.2rem
							text-align: left
							strong
								font-size: inherit
#q6
	padding: 20px
	justify-content: center
	align-items: center
	flex-direction: column
	h2
		color: white
		font-size: 2rem
		font-family: $regular
		text-shadow: 0 0 5px rgba($grey, 0.4)
		display: block
		margin-bottom: 30px
#popupInfo2.small
	.popupContent
		width: auto
		height: auto
		position: relative
		// top: 50%
		// transform: translate(-50%,-50%)
		padding: 60px
		h2
			font-size: 1.6rem
			color: $magenta
			text-align: center
			font-family: $regular

	