@font-face {
  font-family: "Soho Gothic W02 Bold";
  src: url("../fonts/1deecf9a-a2f7-4a5c-b2b7-bf9c5d887ea8.eot?#iefix") format("eot"), url("../fonts/bfbda841-3d51-4049-bd88-c4545129fb40.woff") format("woff"), url("../fonts/4edfc579-e1a4-4e48-bd02-504304f48426.ttf") format("truetype"), url("../fonts/1ac46b2f-2c10-4b28-9451-08c09043d7bd.svg#1ac46b2f-2c10-4b28-9451-08c09043d7bd") format("svg");
}
@font-face {
  font-family: "Soho Gothic W02 Regular";
  src: url("../fonts/171d4b24-9bda-4680-9317-2889552527e1.eot?#iefix") format("eot"), url("../fonts/ae862aff-b472-4735-bb11-c90c9436c784.woff") format("woff"), url("../fonts/744d440a-5776-4a56-9fc8-b0360aff88c2.ttf") format("truetype"), url("../fonts/d8574ab0-5f93-48f0-b4be-d526f26b1921.svg#d8574ab0-5f93-48f0-b4be-d526f26b1921") format("svg");
}
@font-face {
  font-family: "Soho W02 Bold";
  src: url("../fonts/9d479707-a05d-4a33-b778-6a20e68402fd.eot?#iefix") format("eot"), url("../fonts/b0248ed0-c528-4fff-8e96-8aa79502ab22.woff") format("woff"), url("../fonts/4fb9e4a4-33c2-4cb3-8c62-e15595161140.ttf") format("truetype"), url("../fonts/0fb1038e-2df2-4baf-a06c-640ceafbc3f9.svg#0fb1038e-2df2-4baf-a06c-640ceafbc3f9") format("svg");
}
@font-face {
  font-family: "Soho W02 Regular";
  src: url("../fonts/6b05279a-5606-43bd-afe7-51e0c95abf1a.eot?#iefix") format("eot"), url("../fonts/d64e603b-0a58-404f-b477-84fda6183476.woff") format("woff"), url("../fonts/688356fb-4920-4dcb-8ef0-523c96a2128c.ttf") format("truetype"), url("../fonts/b7286ad4-182c-44b8-85cc-00a20641006e.svg#b7286ad4-182c-44b8-85cc-00a20641006e") format("svg");
}
* {
  font-family: "Soho Gothic W02 Regular", sans-serif;
}

@keyframes upDown {
  0%, 40% {
    transform: translate(-50%, 0);
  }
  20% {
    transform: translate(-50%, -1rem);
  }
}
.two--col {
  display: block;
}
.two--col h3 {
  margin-bottom: 1rem;
}
@media (min-width: 560px) {
  .two--col {
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 3rem;
  }
}

.left--content {
  grid-area: left;
}

.right--content {
  grid-area: right;
}

.scrollEvent .left--in {
  transform: translateX(-60%);
  opacity: 0;
  transition: tranform 800ms ease, opacity 800ms ease;
}
.scrollEvent .right--in {
  transform: translateX(60%);
  opacity: 0;
  transition: transform 800ms ease, opacity 800ms ease;
}
.scrollEvent.INTERSECTING .right--in, .scrollEvent.INTERSECTING .left--in {
  transform: translateX(0);
  opacity: 1;
}

@media (min-width: 1000px) {
  .two--one {
    grid-template-columns: 2fr 1fr;
    -ms-grid-columns: 2fr 3rem 1fr;
  }
}

.container {
  padding: 2rem;
}

.padding--section {
  background: white;
  position: relative;
  z-index: 1;
}
@media (min-width: 1000px) {
  .padding--section {
    padding: 5rem 2rem;
  }
}

.align--center {
  align-items: center;
}

.lightgrey {
  background: #e9e9e9;
}

.deep-magenta {
  background-color: #410032;
}

.lines {
  background: url("../img/bg-raster.png") left top repeat;
  background-size: 3rem 3rem;
}

.no--margin {
  margin-bottom: 1rem;
}

.mw--70 {
  max-width: 70rem;
}

.mt {
  margin-top: 2rem;
}

.desktop {
  display: none;
}
@media (min-width: 560px) {
  .desktop {
    display: block;
  }
}

@media (min-width: 560px) {
  .mobile {
    display: none;
  }
}

.icon--row {
  display: flex;
  margin-bottom: 3rem;
  align-items: flex-start;
}
@media (min-width: 560px) {
  .icon--row {
    align-items: center;
  }
}
.icon--row strong {
  display: block;
}
.icon--row img {
  margin-right: 2rem;
  max-width: 60px;
  min-width: 60px;
  width: 60px;
}
@media (min-width: 560px) {
  .icon--row img {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
  }
}

ul li {
  margin-left: 2rem;
  margin-top: 0.5rem;
}

.wintercontent {
  max-width: 95rem !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  text-transform: none;
  text-decoration: none;
}

html, body {
  height: 100%;
  line-height: 1.6;
}

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden;
}

.content {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

small {
  margin-top: 6rem;
  display: block;
}

p, a, strong, li {
  color: black;
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  p, a, strong, li {
    font-size: 1.6rem;
  }
}

strong, span {
  font-size: inherit;
}

strong {
  font-family: "Soho Gothic W02 bold", sans-serif;
}

h1, h2, h3, h4 {
  line-height: 1.2;
}

h3 {
  font-family: "Soho Gothic W02 Regular", sans-serif;
  font-size: 2.4rem;
  margin-bottom: 2rem;
}
h3 span {
  font-family: "Soho Gothic W02 Regular", sans-serif;
}
@media (min-width: 1000px) {
  h3 {
    font-size: 3.5rem;
  }
}

h4 {
  font-family: "Soho Gothic W02 Regular", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
@media (min-width: 1000px) {
  h4 {
    font-size: 2.5rem;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #C70282;
}
.link img {
  width: 1.5rem;
  margin-left: 1rem;
}

header {
  background: url("../img/ot_more_keyvisual.jpg") center no-repeat;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 2rem;
  position: relative;
  background-size: cover;
  overflow: hidden;
}
@media (min-width: 560px) {
  header {
    min-height: 100vh;
    background-attachment: fixed;
  }
}
header.home {
  background-image: url("../img/ot_more_keyvisual-1.jpg");
  background-repeat: no-repeat;
  background-position: right 35% bottom 45%;
  background-size: cover;
}
@media (min-width: 560px) {
  header.home {
    background-position: right 35% bottom 0;
  }
}
header.home #unterschrift {
  max-width: 400px;
  width: 60vw;
  margin-top: 2rem;
  margin-bottom: 30vh;
}
@media (min-width: 560px) {
  header.home #unterschrift {
    margin-top: 4rem;
    margin-bottom: 0;
  }
}
header.winter {
  background-image: url("../img/ot_more_keyvisual-winter.jpg");
  background-repeat: no-repeat;
  background-position: center bottom 45%;
  background-size: cover;
  justify-content: center;
  flex-direction: column;
}
header.winter * {
  color: white;
}
@media (min-width: 560px) {
  header.winter {
    min-height: auto;
    background-position: left 10% bottom 0;
    background-size: cover;
    background-attachment: initial;
  }
}
@media (min-width: 768px) {
  header.winter {
    min-height: 100vh;
    background-attachment: fixed;
    background-position: center bottom 0;
  }
}
header.winter .top__content {
  width: 100%;
  max-width: 140rem;
  position: absolute;
  top: 0;
  padding: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
header.winter .top__content #logo-more {
  width: 20rem;
}
@media (min-width: 768px) {
  header.winter .top__content #logo-more {
    width: 26rem;
  }
}
header.winter .content {
  min-height: 0;
}
header.winter .content .h2 {
  margin-bottom: 3rem;
}
header.winter .content p {
  font-size: 1.6rem;
  max-width: 50vw;
  font-weight: 400;
}
@media (min-width: 768px) {
  header.winter .content p {
    max-width: 40rem;
    font-size: 3rem;
  }
}
header.euha {
  background: url("../img/ot_more_keyvisual-2.jpg");
  background-repeat: no-repeat;
  background-position: right 35% center;
  background-size: cover;
}
@media (min-width: 560px) {
  header.euha {
    background-position: right 35% center;
  }
}
header.euha h1 {
  max-width: 50rem;
}
header .content {
  position: relative;
  min-height: calc(100vh - 4rem);
}
header .content #logo-more {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 40vw;
}
header .content #logo {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 25vw;
}
header .content #down {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  animation: upDown forwards infinite 3s ease;
}
header .content h1 {
  margin-top: 6rem;
  color: white;
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.2);
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
}
@media (min-width: 768px) {
  header .content h1 {
    margin-top: 8rem;
    font-size: 6rem;
  }
}
header .content h2 {
  font-size: 2.4rem;
  color: white;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.2);
  max-width: 50rem;
  line-height: 1.4;
}
@media (min-width: 768px) {
  header .content h2 {
    font-size: 3rem;
  }
}
header .content span.h1 {
  margin-top: 6rem;
  color: white;
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.2);
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  display: block;
}
@media (min-width: 768px) {
  header .content span.h1 {
    margin-top: 8rem;
    font-size: 6rem;
  }
}
header .content span.h2 {
  font-size: 3rem;
  color: white;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.2);
  line-height: 1.2;
  display: block;
}
@media (min-width: 768px) {
  header .content span.h2 {
    font-size: 6rem;
  }
}
header.testimonial {
  background: unset;
  background-color: #410032;
  min-height: 0;
  text-align: center;
}
header.testimonial h1 {
  text-align: center;
}
header.testimonial h2 {
  text-align: center;
  margin: auto;
}
header.testimonial .main--button, header.testimonial #quiz--content .answer--content .questions--container .game--button, #quiz--content .answer--content .questions--container header.testimonial .game--button {
  margin-top: 2rem;
}
header.testimonial .content {
  min-height: 0;
  padding-bottom: 10rem;
  padding-top: 5rem;
}
header.testimonial .content #down img {
  width: 24px;
  height: 24px;
}
header .menu-btn {
  display: none;
}
@media (min-width: 65rem) {
  header .menu-icon {
    display: none;
  }
}
@media (max-width: 65rem) {
  header .header {
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 0;
    top: 0;
  }
  header .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }
  header .header .menu div {
    display: block;
    text-align: right;
    margin-top: 1.5rem;
  }
  header .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 15px;
    user-select: none;
    height: 30px;
  }
  header .header .menu-icon .navicon {
    background: #ffffff;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 21px;
  }
  header .header .menu-icon .navicon:before, header .header .menu-icon .navicon:after {
    background: #ffffff;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }
  header .header .menu-icon .navicon:before {
    top: 7px;
  }
  header .header .menu-icon .navicon:after {
    top: -7px;
  }
  header .header .menu-btn:checked ~ .menu {
    max-height: 240px;
    padding: 4rem 2rem 4rem 6rem;
    width: 100%;
  }
  header .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  header .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
    background: #C70282;
  }
  header .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
    background: #C70282;
  }
  header .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
header .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
}

footer {
  padding: 2rem;
  background: black;
  text-align: center;
}
footer * {
  color: white;
}
@media (min-width: 560px) {
  footer .right--content {
    text-align: right;
  }
}
footer .left--content {
  margin-bottom: 2rem;
}
@media (min-width: 560px) {
  footer .left--content {
    text-align: left;
    margin-bottom: 0;
  }
}

.main--button, #quiz--content .answer--content .questions--container .game--button {
  display: inline-block;
  font-family: "Soho Gothic W02 bold", sans-serif;
  border: 4px solid #410032;
  padding: 0.7rem 2rem;
  transition: all 300ms ease;
  text-align: center;
}
.main--button.main__button-white, #quiz--content .answer--content .questions--container .main__button-white.game--button {
  border-color: white;
  color: white;
  text-align: center !important;
}
.main--button:hover, #quiz--content .answer--content .questions--container .game--button:hover {
  border-color: #C70282;
  color: #C70282;
}

.uc-powered-by-footer {
  display: none !important;
}

.uc-btn-footer-container {
  display: block !important;
}

.btn-list {
  float: none !important;
}

#uc-btn-more-info-banner {
  font-size: 90% !important;
}

#usercentrics-button .uc-btn-new {
  margin: 0 !important;
}

.uc-info-modal-sidebar-nav {
  background: #efefef;
}

.uc-powered-by {
  display: none !important;
}

.uc-embedding-wrapper {
  background: #333 !important;
}

@media (min-width: 65rem) {
  #navigation {
    position: absolute;
    right: 0;
    top: 12px;
    display: flex;
  }
}
@media (min-width: 65rem) {
  #navigation .list {
    padding: 0 2rem;
  }
}
@media (min-width: 65rem) {
  #navigation .list:first-child {
    padding-left: 0;
  }
}
@media (min-width: 65rem) {
  #navigation .list:last-child {
    padding-right: 0;
  }
}
#navigation .list .hover {
  text-align: right;
  padding: 0;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
  position: relative;
  color: #000000;
  font-size: 1.6rem;
  text-transform: uppercase;
}
@media (min-width: 65rem) {
  #navigation .list .hover {
    text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
  }
}
#navigation .list .hover:before, #navigation .list .hover:after {
  content: "";
  position: absolute;
  bottom: -7px;
  width: 0;
  height: 2px;
  margin: 2px 0 0;
  transition: all 0.5s ease-in-out;
  transition-duration: 0.45s;
  opacity: 0;
  background-color: #ae0272;
}
#navigation .list .hover:before {
  left: 50%;
}
#navigation .list .hover:after {
  right: 50%;
}
#navigation .list .hover.active:before, #navigation .list .hover.active:after {
  opacity: 1;
  width: 50%;
}
#navigation .list:hover {
  cursor: pointer;
}
#navigation .list:hover .hover:before, #navigation .list:hover .hover:after {
  opacity: 1;
  width: 50%;
}

.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(44, 49, 51, 0.5);
  z-index: 10000000000000002000;
  padding: 40px;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: none;
}
.popup.active {
  display: flex;
}
.popup .popupContent {
  position: relative;
  top: 0;
  background: white;
  max-width: 1200px;
  padding: 40px;
  width: 100%;
  height: auto;
  overflow: auto;
  z-index: 999999;
}

.close {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(255, 255, 255, 0.6), inset 0 -1px 1px rgba(0, 0, 0, 0.3);
  border: 0px solid transparent;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(to top, #c70282, #ffa0de);
  cursor: pointer;
  transition: all 200ms ease;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999999999999999;
}
.close:before {
  content: "";
  width: 2px;
  height: 10px;
  position: absolute;
  background: white;
  left: 9px;
  top: 5px;
  transform: rotate(45deg);
}
.close:after {
  content: "";
  width: 2px;
  height: 10px;
  position: absolute;
  background: white;
  left: 9px;
  top: 5px;
  transform: rotate(-45deg);
}
.close:hover {
  background: linear-gradient(to top, #c70282, #c70282);
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(255, 255, 255, 0.6), inset 0 -1px 1px rgba(0, 0, 0, 0.3);
}

#popupAlert h2 {
  font-family: "Soho W02 Bold";
  font-size: 1.8em;
  color: #C70282;
  text-align: center;
  max-width: 600px;
  margin: 60px auto 30px;
}
#popupAlert h3 {
  font-family: "Soho Gothic W02 Bold";
  color: #606060;
  text-align: center;
  margin: 0 auto 60px;
}
#popupAlert .soft-row {
  display: flex;
  margin: 0 auto 100px;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
}
#popupAlert .soft-row .alertDevice {
  cursor: pointer;
  text-align: center;
  margin: 0 40px;
}
#popupAlert .soft-row .alertDevice .CIC {
  width: 103px;
  position: relative;
  transition: all 300ms ease;
  top: 0;
  left: 5px;
}
#popupAlert .soft-row .alertDevice .Kanal {
  width: 111px;
  position: relative;
  transition: all 300ms ease;
  top: 0;
  left: -15px;
}
#popupAlert .soft-row .alertDevice .Concha {
  left: -15px;
  width: 136px;
  position: relative;
  transition: all 300ms ease;
  top: 0;
}
#popupAlert .soft-row .alertDevice p {
  margin-top: 10px;
  font-family: "Soho Gothic W02 Bold";
}
#popupAlert .soft-row .alertDevice:hover img {
  top: -5px;
}

#popupInfo .hinweisContent {
  max-width: 600px;
  margin: 30px auto;
}
#popupInfo .hinweisContent #next {
  appearance: none;
  border: none;
  outline: none;
  height: 42px;
  padding: 0 30px;
  color: white;
  background: #5da9c3;
  opacity: 0.25;
  pointer-events: none;
  margin-right: 20px;
}
#popupInfo .hinweisContent #next.active {
  transition: all 300ms ease;
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}
#popupInfo .hinweisContent #next.active:hover {
  background: #82bdd1;
}
#popupInfo .hinweisContent h2 {
  font-size: 1.8em;
  color: #C70282;
  margin-bottom: 20px;
}
#popupInfo .hinweisContent ul, #popupInfo .hinweisContent ol {
  margin: 20px 0;
}
#popupInfo .hinweisContent ul li, #popupInfo .hinweisContent ol li {
  font-size: 1.1rem;
  color: #606060;
  line-height: 1.6;
}
#popupInfo .hinweisContent ol {
  list-style-type: decimal;
}
#popupInfo .hinweisContent ol li {
  margin-bottom: 20px;
  margin-left: 30px;
}
#popupInfo .hinweisContent ol li .numbers {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: white;
  border-radius: 50%;
  background: #C70282;
  margin-right: 10px;
  text-align: center;
  position: absolute;
  left: -30px;
}
#popupInfo .hinweisContent ol li .customCheckbox {
  display: inline-block;
  margin-right: 10px;
}
#popupInfo .hinweisContent ol li .customCheckbox:before {
  content: "";
  position: absolute;
  background: #C70282;
  width: 5px;
  height: 2px;
  transform: rotate(45deg);
  left: 2px;
  bottom: 4px;
}
#popupInfo .hinweisContent ol li .customCheckbox:after {
  content: "";
  position: absolute;
  background: #C70282;
  width: 8px;
  height: 2px;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 5px;
}
#popupInfo .hinweisContent ol li .customCheckbox.preChecked:before {
  background: #4091AD;
}
#popupInfo .hinweisContent ol li .customCheckbox.preChecked:after {
  background: #4091AD;
}
#popupInfo .hinweisContent ol li .customCheckbox.preDefined:before {
  background: #606060;
}
#popupInfo .hinweisContent ol li .customCheckbox.preDefined:after {
  background: #606060;
}
#popupInfo .hinweisContent .strongMagenta {
  font-family: "Soho Gothic W02 Bold";
  color: #C70282;
}
#popupInfo .hinweisContent .strongBlue {
  font-family: "Soho Gothic W02 Bold";
  color: #4091AD;
}
#popupInfo .hinweisContent .strongGrey {
  font-family: "Soho Gothic W02 Bold";
  color: #606060;
}
#popupInfo .hinweisContent a {
  color: #C70282;
}
#popupInfo .hinweisContent p {
  font-family: "Soho Gothic W02 Regular";
  color: #606060;
  font-size: 1.1rem;
  line-height: 1.6;
  display: inline;
}
#popupInfo .hinweisContent p strong {
  font-family: "Soho Gothic W02 Bold";
}
#popupInfo .hinweisContent .popupImageFW {
  max-width: 100%;
  margin: 40px 0;
}

#printPage, #printPage2 {
  font-size: 15px;
  position: fixed;
  width: 210mm;
  height: 297mm;
  left: 0;
  top: 0px;
  background: #fff;
  z-index: 100000000000000020000;
  display: none;
  padding: 60px 40px;
}
#printPage .resultHeader, #printPage2 .resultHeader {
  width: 100%;
  color: #C70282;
  font-size: 3rem;
  margin-bottom: 20px;
}
#printPage .resultBody .resultList, #printPage2 .resultBody .resultList {
  padding: 20px;
  background: #efefef;
  height: 145mm;
  border: 1px solid #ececec;
}
#printPage .resultBody .spriteImage, #printPage2 .resultBody .spriteImage {
  width: 80mm;
  height: 80mm;
  margin-bottom: 40px;
  overflow: hidden;
}
#printPage .resultBody .spriteImage img, #printPage2 .resultBody .spriteImage img {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  transform: translate(-50%);
}
#printPage .resultBody h3, #printPage2 .resultBody h3 {
  margin-bottom: 20px;
  display: block;
  font-family: "Soho W02 Bold";
  font-size: 1.3rem;
  color: #606060;
}
#printPage .resultBody ul, #printPage2 .resultBody ul {
  margin-bottom: 20px;
}
#printPage .resultBody .resultImage, #printPage2 .resultBody .resultImage {
  position: absolute;
  left: 340px;
  top: 0;
  height: 80mm;
  width: 99mm;
  border: 1px solid #ececec;
}
#printPage .resultBody .resultImage img, #printPage2 .resultBody .resultImage img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#print {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 100px;
  cursor: pointer;
  font-size: 0.925rem;
}
#print img {
  width: 20px;
  margin-right: 7px;
  position: relative;
  top: 4px;
}

#printLogo {
  width: 30mm;
  position: absolute;
  bottom: 0mm;
  right: 10mm;
  height: 30mm;
}

#popupBauform, #popupAuswahl {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.5);
  z-index: 10000000000000002000000;
  padding: 30px;
  display: none;
}
#popupBauform .popupBauformContent, #popupAuswahl .popupBauformContent {
  background: #fff;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  max-width: 640px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 1px 0 15px rgba(0, 0, 0, 0.4);
  padding: 60px 40px 60px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
}
#popupBauform .popupBauformContent button, #popupAuswahl .popupBauformContent button {
  padding: 10px 20px;
  background: #C70282;
  color: white;
  border: none;
  outline: none;
}
#popupBauform .popupBauformContent h2, #popupAuswahl .popupBauformContent h2 {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  margin: 0 auto 20px;
  font-family: "Soho W02 Bold";
  color: #C70282;
  text-align: center;
}
#popupBauform .popupBauformContent .choose, #popupAuswahl .popupBauformContent .choose {
  text-align: center !important;
  min-width: 20%;
  cursor: pointer;
}
#popupBauform .popupBauformContent .choose img, #popupAuswahl .popupBauformContent .choose img {
  display: block;
  margin: 0 auto 10px;
}

.hidden {
  display: none !important;
}

@media print {
  .mainContent {
    display: none;
  }
  #printPage, #printPage2 {
    display: block !important;
  }
}
.listeHinweis {
  margin-top: 0 !important;
}
.listeHinweis li {
  margin-bottom: 0 !important;
}

.modal__winterglueck {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../img/purple_bg.jpg") center no-repeat;
  background-size: cover;
  z-index: 99;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  display: none;
}
.modal__winterglueck.active {
  display: flex;
}
.modal__winterglueck .banner__winterglueck {
  background: white;
  padding: 4rem;
  width: 100%;
  max-width: 50rem;
  position: relative;
  transform: rotate(-3deg);
  box-shadow: -0.5rem 0.5rem 3rem rgba(0, 0, 0, 0.45);
  text-align: center;
  z-index: 0;
}
.modal__winterglueck .banner__winterglueck:after {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  border: 2px solid black;
  pointer-events: none;
}
.modal__winterglueck .banner__winterglueck p {
  margin-bottom: 2rem;
}
.modal__winterglueck .banner__winterglueck .main--button, .modal__winterglueck .banner__winterglueck #quiz--content .answer--content .questions--container .game--button, #quiz--content .answer--content .questions--container .modal__winterglueck .banner__winterglueck .game--button {
  cursor: pointer;
}

.youtube #main {
  width: 100%;
}
.youtube .youtube-player {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.youtube .youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}
.youtube .youtube-player img {
  object-fit: cover;
  display: block;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  transition: 0.4s all;
}
.youtube .youtube-player img:hover {
  filter: brightness(75%);
}
.youtube .youtube-player .play {
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  cursor: pointer;
  width: 91px;
  height: 91px;
  transition: background-color 100ms linear;
  border-radius: 50px;
  opacity: 0.8;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.youtube .youtube-player .playbutton {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -5px;
  z-index: 99;
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 500 500' enable-background='new 0 0 500 500' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23C70282' d='M35.6,6c16.8,10.8,33.5,21.7,50.3,32.4c109.4,69.6,218.8,139.2,328.2,208.8c1.2,0.8,2.3,1.6,4.1,2.8 c-2.3,1.5-4.2,2.7-6.2,4C288,333,163.8,412,39.7,491c-1.4,0.9-2.8,2-4.2,3c-0.3,0-0.6,0-1,0c0.1-1.1,0.2-2.2,0.2-3.3 c0-160.5,0-320.9,0-481.4c0-1.1-0.1-2.2-0.2-3.3C35,6,35.3,6,35.6,6z'/%3E%3C/g%3E%3C/svg%3E");
}

#botschafterin h1 {
  font-family: "Soho Gothic W02 Regular", sans-serif;
  font-size: 2.4rem;
  margin-bottom: 2rem;
}
@media (min-width: 1000px) {
  #botschafterin h1 {
    font-size: 3.5rem;
  }
}
#botschafterin h2 {
  font-size: 2.4rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  max-width: 50rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  #botschafterin h2 {
    font-size: 3rem;
  }
}

@media (min-width: 1000px) {
  #interview.padding--section {
    padding: 10rem 2rem;
  }
}
#interview h2 {
  font-family: "Soho Gothic W02 Regular", sans-serif;
  font-size: 2.4rem;
  color: #ffffff;
}
@media (min-width: 1000px) {
  #interview h2 {
    font-size: 3.5rem;
  }
}
#interview h3 {
  font-size: 2.4rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  line-height: 1.4;
  margin-bottom: 1rem;
  color: #ffffff;
  max-width: 46rem;
}
@media (min-width: 768px) {
  #interview h3 {
    font-size: 3rem;
  }
}
#interview p {
  color: #ffffff;
}

#premiere {
  position: relative;
  background: white;
}
#premiere h3 {
  max-width: 25ch;
}
#premiere #gewinnspiel {
  background: url("../img/ot_bg_cubes.jpg") center no-repeat;
  background-size: cover;
  padding: 2rem;
}
#premiere .right--content {
  display: flex;
  align-items: center;
}

#brainhearing {
  padding-bottom: 0;
  padding-top: 0;
}
@media (min-width: 560px) {
  #brainhearing .two--col {
    display: block;
  }
}
@media (min-width: 768px) {
  #brainhearing .two--col {
    display: grid;
  }
}
#brainhearing .align--center {
  align-items: stretch;
}
@media (min-width: 768px) {
  #brainhearing .left--content .container {
    padding: 6rem 2rem;
  }
}
#brainhearing .right--content {
  position: relative;
  padding: 2rem;
  padding-bottom: 0;
}
#brainhearing .right--content img {
  max-width: 60vw;
  margin: 0 auto;
}
@media (min-width: 768px) {
  #brainhearing .right--content {
    padding: 0;
  }
  #brainhearing .right--content img {
    max-width: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

#more {
  padding: 2rem;
  background-color: #fff;
}
@media (min-width: 768px) {
  #more {
    padding: 5rem 2rem;
  }
}
#more .mobile--grafik {
  width: 30rem;
  margin: 2rem auto;
}
@media (min-width: 300px) {
  #more .mobile--grafik {
    display: none;
  }
}
#more .tablet--grafik {
  display: none;
  width: 50rem;
  margin: 2rem 0;
}
@media (min-width: 300px) {
  #more .tablet--grafik {
    display: block;
  }
}
@media (min-width: 1000px) {
  #more .tablet--grafik {
    display: none;
  }
}
#more .desktop--grafik {
  display: none;
}
@media (min-width: 1000px) {
  #more .desktop--grafik {
    display: block;
  }
}

@media (min-width: 1000px) {
  #youtube-einzeln.padding--section {
    padding: 10rem 2rem;
  }
}
#youtube-einzeln h2 {
  font-family: "Soho Gothic W02 Regular", sans-serif;
  font-size: 2.4rem;
  margin-bottom: 2rem;
}
@media (min-width: 1000px) {
  #youtube-einzeln h2 {
    font-size: 3.5rem;
  }
}
#youtube-einzeln .two--col {
  display: block;
}
@media (min-width: 768px) {
  #youtube-einzeln .desktop {
    display: none;
  }
  #youtube-einzeln .two--col {
    display: block;
  }
}
@media (min-width: 1000px) {
  #youtube-einzeln .two--col {
    display: grid;
    align-items: center;
  }
}

@media (min-width: 1000px) {
  .youtube-alle.padding--section {
    padding: 10rem 2rem 10rem;
  }
}
.youtube-alle .two--col {
  display: block;
}
@media (max-width: 999px) {
  .youtube-alle .two--col {
    display: flex !important;
    flex-direction: column !important;
  }
}
@media (min-width: 768px) {
  .youtube-alle .desktop {
    display: none;
  }
  .youtube-alle .two--col {
    display: block;
  }
}
@media (min-width: 1000px) {
  .youtube-alle .two--col {
    display: grid;
    align-items: center;
  }
}

@media (max-width: 999px) {
  .y1 .left--content,
.y3 .left--content,
.y5 .left--content {
    order: 2;
  }
  .y1 .right--content,
.y3 .right--content,
.y5 .right--content {
    order: 1;
  }
}
#hoersysteme .desktop {
  display: none;
  width: 100%;
}
#hoersysteme .two--col {
  display: block;
}
@media (min-width: 768px) {
  #hoersysteme .desktop {
    display: none;
  }
  #hoersysteme .two--col {
    display: block;
  }
}
@media (min-width: 1000px) {
  #hoersysteme .two--col {
    display: grid;
    align-items: center;
  }
}
#hoersysteme .neural {
  background: url("../img/ot-neural.jpg") right center no-repeat;
  background-size: cover;
  min-height: 50vw;
}
@media (min-width: 1000px) {
  #hoersysteme .neural {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
  }
  #hoersysteme .neural .desktop {
    display: block;
  }
}
#hoersysteme .icons {
  padding: 2rem;
}
#hoersysteme .icons h4 {
  margin-bottom: 4rem;
}
#hoersysteme .icons .main--button, #hoersysteme .icons #quiz--content .answer--content .questions--container .game--button, #quiz--content .answer--content .questions--container #hoersysteme .icons .game--button {
  margin-top: 3rem;
}

#more-infos.padding--section {
  padding: 2rem;
}
@media (min-width: 1000px) {
  #more-infos.padding--section {
    padding: 4rem 2rem 10rem;
  }
}
#more-infos .container {
  padding: 6rem 4rem;
}
#more-infos p {
  max-width: 40rem;
  margin-bottom: 4rem;
  font-size: 2rem;
}
#more-infos .left--content,
#more-infos .right--content {
  border: 4px solid #410032;
}
#more-infos .right--content {
  margin-top: 2rem;
}
@media (min-width: 560px) {
  #more-infos .right--content {
    margin-top: 0;
  }
}

#more-testimonial.padding--section {
  padding: 2rem;
}
@media (min-width: 1000px) {
  #more-testimonial.padding--section {
    padding: 10rem 2rem 10rem;
  }
}
#more-testimonial p {
  max-width: 40rem;
  margin-bottom: 4rem;
  font-size: 2rem;
}
#more-testimonial .container {
  padding: 6rem 4rem;
  border: 4px solid #410032;
  max-width: 800px;
  margin: auto;
}

@media (min-width: 1000px) {
  #more-euha.padding--section {
    padding: 10rem 2rem 10rem;
  }
}
#more-euha .content {
  border: 4px solid #410032;
  padding: 0;
}
#more-euha .two--col {
  display: block;
}
@media (min-width: 768px) {
  #more-euha .two--col {
    display: block;
  }
}
@media (min-width: 1000px) {
  #more-euha .two--col {
    display: grid;
    align-items: center;
  }
}

#akku {
  padding: 5rem 2rem;
}
#akku h4 {
  font-family: "Soho Gothic W02 Regular", sans-serif;
}
#akku h4.mt {
  max-width: 100%;
  margin-top: 6rem;
}
#akku .right--content h4 {
  margin-top: 4rem;
}
@media (min-width: 560px) {
  #akku .right--content h4 {
    margin-top: 0;
  }
}
#akku #streaming {
  margin-top: 2rem;
}
#akku strong {
  display: block;
}
#akku #more--geraet {
  margin: 2rem auto;
}
#akku #more--akku {
  margin: 2rem auto;
}
#akku .farbpalette {
  margin: 4rem 0 2rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
#akku .farbpalette .geraet {
  flex: 1 0 calc(50% - 2rem);
  -ms-flex: 1 0 45%;
  text-align: center;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
@media (min-width: 560px) {
  #akku .farbpalette .geraet {
    flex: 1 0 calc(25% - 4rem);
    -ms-flex: 1 0 18%;
  }
}
@media (min-width: 1000px) {
  #akku .farbpalette .geraet {
    flex: 1 0 calc(12.5% - 2rem);
    -ms-flex: 1 0 10%;
  }
}
#akku .farbpalette .geraet p {
  position: relative;
  transform: translateX(-12%);
}
#akku .farbpalette .geraet strong {
  margin-top: 2rem;
  text-transform: uppercase;
}
#akku .farbpalette .geraet:nth-child(2n) {
  margin-right: 0;
}
@media (min-width: 560px) {
  #akku .farbpalette .geraet {
    margin-right: 4rem !important;
  }
  #akku .farbpalette .geraet:nth-child(4n) {
    margin-right: 0 !important;
  }
}
@media (min-width: 1000px) {
  #akku .farbpalette .geraet {
    margin-right: 2rem !important;
  }
  #akku .farbpalette .geraet:nth-child(4n) {
    margin-right: 2rem !important;
  }
  #akku .farbpalette .geraet:last-child {
    margin-right: 0 !important;
  }
}

@media (min-width: 1000px) {
  #euhaGewinnspiel.padding--section {
    padding: 0 2rem;
    z-index: 999;
  }
}

.youtube__content {
  max-width: 100rem;
  text-align: center;
  padding: 0 2rem;
}
.youtube__content .youtube-player {
  margin-top: 2rem;
}
.youtube__content .video__thumbnails {
  margin: 2rem 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-areas: "g1 g2" "g3 g4" "g5 g6";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
@media (min-width: 768px) {
  .youtube__content .video__thumbnails {
    display: grid;
    grid-gap: 2rem;
    grid-template-areas: "g1 g2 g3" "g4 g5 g6";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}
.youtube__content .video__thumbnails .video {
  cursor: pointer;
  border: 2px solid white;
}
.youtube__content .video__thumbnails .video.active {
  border: 2px solid #C70282;
}
.youtube__content .video__thumbnails .video:nth-child(1) {
  grid-area: g1;
}
.youtube__content .video__thumbnails .video {
  cursor: pointer;
  border: 2px solid white;
}
.youtube__content .video__thumbnails .video.active {
  border: 2px solid #C70282;
}
.youtube__content .video__thumbnails .video:nth-child(2) {
  grid-area: g2;
}
.youtube__content .video__thumbnails .video {
  cursor: pointer;
  border: 2px solid white;
}
.youtube__content .video__thumbnails .video.active {
  border: 2px solid #C70282;
}
.youtube__content .video__thumbnails .video:nth-child(3) {
  grid-area: g3;
}
.youtube__content .video__thumbnails .video {
  cursor: pointer;
  border: 2px solid white;
}
.youtube__content .video__thumbnails .video.active {
  border: 2px solid #C70282;
}
.youtube__content .video__thumbnails .video:nth-child(4) {
  grid-area: g4;
}
.youtube__content .video__thumbnails .video {
  cursor: pointer;
  border: 2px solid white;
}
.youtube__content .video__thumbnails .video.active {
  border: 2px solid #C70282;
}
.youtube__content .video__thumbnails .video:nth-child(5) {
  grid-area: g5;
}

.soft--row-ot {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
@media (min-width: 1000px) {
  .soft--row-ot {
    align-items: center;
  }
}
.soft--row-ot .content {
  flex: 1 0 100%;
  order: 2;
}
.soft--row-ot .content__image {
  order: 1;
  flex: 1 0 100%;
  padding: 1rem;
}
.soft--row-ot .content__image img {
  width: 25rem;
  margin: 0 auto;
}
@media (min-width: 1000px) {
  .soft--row-ot .content {
    order: 1;
    flex: 1 0 60%;
  }
  .soft--row-ot .content__image {
    order: 2;
    flex: 1 0 40%;
    padding: 1rem;
  }
  .soft--row-ot .content__image img {
    max-width: 100%;
  }
}

#login {
  position: relative;
}
#login:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
#login .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
#login .content h1 {
  margin-top: 0;
}

form {
  background: white;
  padding: 2rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 40rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}
form * {
  font-size: 1.6rem;
}
form input[type=text] {
  padding: 0.5rem 1rem;
  display: block;
  margin-bottom: 1rem;
  width: 100%;
}
form .checkbox--group {
  margin: 2rem 0;
}
form .error {
  margin-top: 2rem;
  color: red;
}

#quiz-header {
  background-image: url("../img/header-quiz.jpg");
  background-position: top center;
  background-attachment: initial;
  position: relative;
}
#quiz-header .anchor--link {
  display: none;
}
@media (min-width: 900px) {
  #quiz-header .anchor--link {
    display: block;
  }
}
@media (min-width: 560px) {
  #quiz-header {
    background-attachment: fixed;
  }
}
#quiz-header:before {
  content: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 90%;
  background: linear-gradient(to right, black, rgba(0, 0, 0, 0));
}
#quiz-header .content {
  display: flex;
  align-items: flex-end;
}
@media (min-width: 900px) {
  #quiz-header .content {
    align-items: center;
  }
}
#quiz-header .container {
  margin-top: 4rem;
  background: rgba(255, 255, 255, 0.8);
}
@media (min-width: 900px) {
  #quiz-header .container {
    width: 50%;
  }
}
#quiz-header .container h1 {
  font-size: 2.4rem;
  margin: 0;
  color: black;
  text-shadow: none;
  margin-bottom: 2rem;
}
@media (min-width: 900px) {
  #quiz-header .container h1 {
    font-size: 3.4rem;
  }
}
#quiz h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 560px) {
  #quiz h2 {
    font-size: 4rem;
  }
}
#quiz h2, #quiz p {
  max-width: 90rem;
}

#quiz--content {
  margin-top: 2rem;
}
@media (max-width: 768px) {
  #quiz--content .two--col {
    display: block;
  }
}
#quiz--content .left--col,
#quiz--content .right--col {
  padding: 2rem;
}
#quiz--content .current--question h3 {
  flex-grow: 1;
  min-width: 80%;
  margin-bottom: 10px;
  font-size: 1.8rem;
}
@media (min-width: 560px) {
  #quiz--content .current--question h3 {
    margin-bottom: 20px;
    font-size: 2.4rem;
  }
}
#quiz--content .answer--content {
  flex-grow: 1;
}
@media (min-width: 560px) {
  #quiz--content .answer--content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
#quiz--content .answer--content .error--message {
  width: 80%;
  font-size: 1.6rem;
  color: #C70282;
}
#quiz--content .answer--content .questions--container {
  margin: 20px 0;
  flex-grow: 1;
  min-width: 80%;
  display: block;
  flex-wrap: wrap;
}
@media (min-width: 560px) {
  #quiz--content .answer--content .questions--container {
    margin-right: -20px;
  }
}
#quiz--content .answer--content .questions--container .game--button {
  display: flex;
  align-items: center;
  flex: 1 0 80%;
  margin-bottom: 10px;
  font-size: 1.4rem;
  cursor: pointer;
}
@media (min-width: 560px) {
  #quiz--content .answer--content .questions--container .game--button {
    flex: 1 0 45%;
    margin-right: 20px;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
#quiz--content .answer--content .questions--container input[type=radio] {
  display: none;
}
#quiz--content .answer--content .questions--container input[data-checked=true] + .game--button {
  border-color: #C70282;
}
#quiz--content .progress--bar {
  width: auto;
  text-align: center;
  background: white;
  display: flex;
  flex-direction: row-reverse;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}
@media (min-width: 560px) {
  #quiz--content .progress--bar {
    display: flex;
  }
}
#quiz--content .progress--bar div {
  font-size: 1rem;
  flex-grow: 1;
  color: transparent;
  height: 100%;
  padding: 5px 0px;
}
@media (min-width: 560px) {
  #quiz--content .progress--bar div {
    color: black;
    padding: 5px 10px;
    font-size: 1.6rem;
  }
}
#quiz--content .progress--bar div.active {
  background: #C70282;
}
@media (min-width: 560px) {
  #quiz--content .progress--bar div.active {
    color: white;
  }
}
#quiz--content form#quiz--form {
  width: 100%;
  max-width: 100%;
}
#quiz--content form#quiz--form h4 {
  color: #C70282;
  font-size: 3rem;
  margin-bottom: 0;
}
#quiz--content form#quiz--form input[type=text],
#quiz--content form#quiz--form input[type=email] {
  width: 100%;
  padding: 0.5rem 1rem;
  background: white;
  border: 1px solid #c3c3c3;
  margin-bottom: 1rem;
}
#quiz--content form#quiz--form button {
  cursor: pointer;
}
#quiz--content form#quiz--form .toggle--group {
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  #quiz--content form#quiz--form .toggle--group {
    align-items: center;
  }
}
#quiz--content form#quiz--form .toggle--group .toggle--switch {
  height: 2.5rem;
  width: 4rem;
  min-width: 4rem;
  background: #c3c3c3;
  border-radius: 3rem;
  margin-right: 1rem;
  position: relative;
  transition: all 150ms ease;
}
#quiz--content form#quiz--form .toggle--group .toggle--switch:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 2.1rem;
  height: 2.1rem;
  background: #3B3C3D;
  border-radius: 50%;
  transition: all 150ms ease;
}
#quiz--content form#quiz--form .toggle--group p {
  display: block;
  font-size: 1.2rem;
}
#quiz--content form#quiz--form .toggle--group p a {
  font-size: inherit;
  text-decoration: underline;
}
#quiz--content form#quiz--form .toggle--group input {
  display: none;
}
#quiz--content form#quiz--form .toggle--group input:checked ~ .toggle--switch {
  background: #C70282;
}
#quiz--content form#quiz--form .toggle--group input:checked ~ .toggle--switch:before {
  transform: translateX(1.5rem);
  background: white;
}
#quiz--content form#quiz--form small {
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #C70282;
}

.centered--button {
  display: inline-block;
  margin: 0 auto;
}

#solution {
  padding: 6rem 2rem;
}
#solution .link {
  margin-top: 1rem;
  display: inline-flex;
}
#solution a:not(.link):not(.main--button) {
  font-weight: bold;
  text-decoration: underline;
}

#gewinne {
  background: url("../img/ot_bg_cubes.jpg") center center no-repeat;
  background-size: cover;
  position: relative;
}
#gewinne:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
@media (min-width: 768px) {
  #gewinne:before {
    height: 100%;
    width: 50%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
}
#gewinne h3 {
  max-width: 30ch;
  font-size: 2.4rem;
  margin: 0;
  color: white;
  text-shadow: none;
  margin-bottom: 2rem;
}
@media (min-width: 900px) {
  #gewinne h3 {
    font-size: 3.4rem;
  }
}
#gewinne * {
  position: relative;
  color: white;
}
#gewinne .right--content {
  padding: 0 0 2rem 2rem;
}
@media (min-width: 768px) {
  #gewinne .right--content {
    padding: 8rem 0;
  }
}

.cube--3D {
  margin: 4rem 0;
}
@media (min-width: 768px) {
  .cube--3D {
    margin: 0;
  }
}
.cube--3D #D3Cube {
  transform-style: preserve-3d;
  margin: auto;
  position: relative;
  transition: transform 0.5s ease-in-out;
  width: 65vw;
  height: 65vw;
}
@media (min-width: 768px) {
  .cube--3D #D3Cube {
    width: 35vw;
    height: 35vw;
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube {
    width: 40rem;
    height: 40rem;
  }
}
.cube--3D #D3Cube > div {
  position: absolute;
  transition: background-color 0.3s ease-in-out;
  width: 65vw;
  height: 65vw;
  float: left;
  overflow: hidden;
  opacity: 0.75;
  background-color: #C70282;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .cube--3D #D3Cube > div {
    width: 35vw;
    height: 35vw;
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube > div {
    width: 40rem;
    height: 40rem;
  }
}
.cube--3D #D3Cube > div span {
  padding: 2rem;
  text-align: center;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: opacity 300ms ease;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
}
@media (max-width: 360px) {
  .cube--3D #D3Cube > div span {
    font-size: 1.4rem;
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube > div span {
    font-size: 2rem;
  }
}
.cube--3D #D3Cube > div:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: inset 0 0 10px 5px rgba(254, 151, 218, 0.2);
}
.cube--3D #D3Cube #side1 {
  transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(32.5vw);
  background-color: #ed0f9f !important;
}
@media (min-width: 768px) {
  .cube--3D #D3Cube #side1 {
    transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(17.5vw);
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube #side1 {
    transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(20rem);
  }
}
.cube--3D #D3Cube #side2 {
  transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(32.5vw);
}
@media (min-width: 768px) {
  .cube--3D #D3Cube #side2 {
    transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(17.5vw);
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube #side2 {
    transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(20rem);
  }
}
.cube--3D #D3Cube #side3 {
  transform: translateX(0px) translateY(0px) translateZ(32.5vw);
}
@media (min-width: 768px) {
  .cube--3D #D3Cube #side3 {
    transform: translateX(0px) translateY(0px) translateZ(17.5vw);
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube #side3 {
    transform: translateX(0px) translateY(0px) translateZ(20rem);
  }
}
.cube--3D #D3Cube #side4 {
  transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(32.5vw);
}
@media (min-width: 768px) {
  .cube--3D #D3Cube #side4 {
    transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(17.5vw);
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube #side4 {
    transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(20rem);
  }
}
.cube--3D #D3Cube #side5 {
  transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(32.5vw);
}
@media (min-width: 768px) {
  .cube--3D #D3Cube #side5 {
    transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(17.5vw);
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube #side5 {
    transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(20rem);
  }
}
.cube--3D #D3Cube #side6 {
  transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(32.5vw);
  background-color: #620140 !important;
  box-shadow: 0 0 120px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) {
  .cube--3D #D3Cube #side6 {
    transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(17.5vw);
  }
}
@media (min-width: 1000px) {
  .cube--3D #D3Cube #side6 {
    transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(20rem);
  }
}
.cube--3D #D3Cube.seite1 {
  transform: rotateX(-15deg) rotateY(115deg) rotateZ(0deg);
}
.cube--3D #D3Cube.seite1 #s5 {
  display: none;
}
.cube--3D #D3Cube.seite1 #side2 {
  background-color: #C70282;
}
.cube--3D #D3Cube.seite1 #side2 span {
  opacity: 1;
}
.cube--3D #D3Cube.seite1 #side5 {
  background-color: #ed0f9f !important;
}
.cube--3D #D3Cube.seite1 #side3 {
  background-color: #f99cd8 !important;
}
.cube--3D #D3Cube.seite1 #side4 {
  background-color: #f33cb3 !important;
}
.cube--3D #D3Cube.seite2 {
  transform: rotateX(-15deg) rotateY(25deg) rotateZ(0deg);
}
.cube--3D #D3Cube.seite2 #s6 {
  display: none;
}
.cube--3D #D3Cube.seite2 #side3 {
  background-color: #C70282;
}
.cube--3D #D3Cube.seite2 #side3 span {
  opacity: 1;
}
.cube--3D #D3Cube.seite2 #side2 {
  background-color: #ed0f9f !important;
}
.cube--3D #D3Cube.seite2 #side4 {
  background-color: #f99cd8 !important;
}
.cube--3D #D3Cube.seite2 #side5 {
  background-color: #f33cb3 !important;
}
.cube--3D #D3Cube.seite3 {
  transform: rotateX(-15deg) rotateY(-65deg) rotateZ(0deg);
}
.cube--3D #D3Cube.seite3 #s7 {
  display: none;
}
.cube--3D #D3Cube.seite3 #side4 span {
  opacity: 1;
}
.cube--3D #D3Cube.seite3 #side5 {
  background-color: #f99cd8 !important;
}
.cube--3D #D3Cube.seite3 #side3 {
  background-color: #ed0f9f !important;
}
.cube--3D #D3Cube.seite3 #side2 {
  background-color: #f33cb3 !important;
}
.cube--3D #D3Cube.seite4 {
  transform: rotateX(-15deg) rotateY(-155deg) rotateZ(0deg);
}
.cube--3D #D3Cube.seite4 #side5 {
  background-color: #C70282;
}
.cube--3D #D3Cube.seite4 #side5 span {
  opacity: 1;
}
.cube--3D #D3Cube.seite4 #side4 {
  background-color: #ed0f9f !important;
}
.cube--3D #D3Cube.seite4 #side2 {
  background-color: #f99cd8 !important;
}
.cube--3D #D3Cube.seite4 #side3 {
  background-color: #f33cb3 !important;
}
.cube--3D #D3Cube.seite5 {
  transform: rotateX(-15deg) rotateY(-245deg) rotateZ(0deg);
}
.cube--3D #D3Cube.seite5 #s1 {
  display: none;
}
.cube--3D #D3Cube.seite5 #side2 {
  background-color: #C70282;
}
.cube--3D #D3Cube.seite5 #side2 span {
  opacity: 1;
}
.cube--3D #D3Cube.seite5 #side5 {
  background-color: #ed0f9f !important;
}
.cube--3D #D3Cube.seite5 #side3 {
  background-color: #f99cd8 !important;
}
.cube--3D #D3Cube.seite5 #side4 {
  background-color: #f33cb3 !important;
}
.cube--3D #D3Cube.seite6 {
  transform: rotateX(-15deg) rotateY(-335deg) rotateZ(0deg);
}
.cube--3D #D3Cube.seite6 #s2 {
  display: none;
}
.cube--3D #D3Cube.seite6 #side3 {
  background-color: #C70282;
}
.cube--3D #D3Cube.seite6 #side3 span {
  opacity: 1;
}
.cube--3D #D3Cube.seite6 #side2 {
  background-color: #ed0f9f !important;
}
.cube--3D #D3Cube.seite6 #side4 {
  background-color: #f99cd8 !important;
}
.cube--3D #D3Cube.seite6 #side5 {
  background-color: #f33cb3 !important;
}
.cube--3D #D3Cube.seite7 {
  transform: rotateX(-15deg) rotateY(-425deg) rotateZ(0deg);
}
.cube--3D #D3Cube.seite7 #s3 {
  display: none;
}
.cube--3D #D3Cube.seite7 #side4 span {
  opacity: 1;
}
.cube--3D #D3Cube.seite7 #side5 {
  background-color: #f99cd8 !important;
}
.cube--3D #D3Cube.seite7 #side3 {
  background-color: #ed0f9f !important;
}
.cube--3D #D3Cube.seite7 #side2 {
  background-color: #f33cb3 !important;
}

#gewinnspiel {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  box-shadow: unset;
}
#gewinnspiel .gewinnspielContent {
  display: flex;
  overflow: hidden;
  height: calc(100% - 51px);
}
#gewinnspiel .soft-row {
  display: flex;
  min-width: 100%;
}
#gewinnspiel .soft-row .col-1 {
  text-align: center;
  width: 100%;
}
#gewinnspiel .soft-row .col-1 label[for=accept] {
  display: flex;
  align-items: flex-start;
}
#gewinnspiel .soft-row .col-1 label[for=accept] .customCheckbox {
  position: relative;
  left: 0;
}
#gewinnspiel .soft-row .col-1 .sternchentext {
  color: white;
  font-size: 12px;
  margin-top: 30px;
}
#gewinnspiel .soft-row .col-1 .sternchentext strong {
  font-size: inherit;
}
#gewinnspiel .soft-row .col-1 .customCheckbox {
  top: 2px;
  width: 15px;
  height: 15px;
  background: #f0f0f0;
  border: 1px solid #a3a3a3;
  display: inline-block;
  margin-right: 7px;
  position: relative;
  margin-top: 4px;
}
#gewinnspiel .soft-row .col-1 .allquestions {
  display: inline-block;
  text-align: left;
  max-width: 60rem;
  margin: 0 auto;
}
#gewinnspiel .soft-row .col-1 .allquestions .inputGroup {
  display: block;
}
#gewinnspiel .soft-row .col-1 .inputGroupForm {
  margin: 0;
}
#gewinnspiel .soft-row .col-1 .inputGroupForm input {
  appearance: none;
  border-radius: none;
  width: 100%;
  padding: 10px;
  border: 1px solid #707070;
  background: #f0f0f0;
  outline: none;
  margin-bottom: 20px;
}
#gewinnspiel .soft-row .col-1 input[type=radio], #gewinnspiel .soft-row .col-1 input[type=checkbox] {
  display: none;
  position: absolute;
  left: -100000px;
}
#gewinnspiel .soft-row .col-1 input[type=radio]:checked ~ .customCheckbox:before, #gewinnspiel .soft-row .col-1 input[type=checkbox]:checked ~ .customCheckbox:before {
  content: "";
  position: absolute;
  background: #C70282;
  width: 7px;
  height: 3px;
  transform: rotate(45deg);
  left: 2.5px;
  bottom: 5px;
}
#gewinnspiel .soft-row .col-1 input[type=radio]:checked ~ .customCheckbox:after, #gewinnspiel .soft-row .col-1 input[type=checkbox]:checked ~ .customCheckbox:after {
  content: "";
  position: absolute;
  background: #C70282;
  width: 12px;
  height: 3px;
  transform: rotate(-45deg);
  left: 5px;
  bottom: 7px;
}
#gewinnspiel .soft-row .col-1 label {
  font-family: "Soho Gothic W02 Regular", sans-serif;
  font-size: 2rem;
  color: #ffffff;
  display: block;
  cursor: pointer;
  line-height: 20px;
  position: relative;
  margin: 25px auto;
  text-align: left;
}
@media (min-width: 1000px) {
  #gewinnspiel .soft-row .col-1 label {
    font-size: 2.5rem;
  }
}
#gewinnspiel .soft-row .col-1 label .customCheckbox {
  width: 20px;
  height: 20px;
}
@media (min-width: 1000px) {
  #gewinnspiel .soft-row .col-1 label .customCheckbox {
    position: absolute;
    left: -35px;
    top: -5px;
  }
}
#gewinnspiel .soft-row .col-1 .question {
  font-size: 2.5rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  color: #ffffff;
  margin: 20px auto;
  max-width: 60rem;
  line-height: 1.3;
}
@media (min-width: 1000px) {
  #gewinnspiel .soft-row .col-1 .question {
    font-size: 3.5rem;
  }
}
#gewinnspiel .btns {
  padding: 20px;
  z-index: 99999999999;
}
#gewinnspiel .btns .next {
  background: #ffffff;
  color: #000000;
  padding: 20px;
  border: none;
  outline: none;
  width: 200px;
  font-size: 2rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}
#gewinnspiel .btns .prev {
  background: #707070;
  color: white;
  margin-top: 30px;
  padding: 20px;
  border: none;
  outline: none;
  width: 200px;
  font-size: 2rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  margin-bottom: 2rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}
@media (min-width: 1000px) {
  #gewinnspiel .btns .prev {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
#gewinnspiel .btns input[type=submit] {
  appearance: none;
  background: #ffffff;
  color: #000000;
  padding: 20px;
  border: none;
  outline: none;
  width: 200px;
  font-size: 2rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  cursor: pointer;
  border-radius: 0;
}
#gewinnspiel .frage {
  transition: margin 400ms ease;
}
#gewinnspiel .frage .col-1:first-child {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#gewinnspiel .quizForm {
  height: 0;
  transition: all 400ms ease;
}
#gewinnspiel .quizForm h2 {
  color: white;
  font-size: 1.4rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  margin-bottom: 30px;
}
#gewinnspiel .quizForm label {
  max-width: 100%;
  line-height: 1.3;
  font-size: 2rem;
}
@media (min-width: 1000px) {
  #gewinnspiel .quizForm label {
    margin-left: 35px;
  }
}
#gewinnspiel .quizForm.active {
  height: auto;
}
#gewinnspiel .quizForm .col-1:last-child .soft-row {
  align-items: center;
  margin-bottom: 20px;
}
#gewinnspiel .quizForm .col-1:last-child .soft-row .col-1 {
  background: none;
  padding: 0;
  text-align: center;
}
#gewinnspiel .quizForm .col-1:last-child .soft-row .col-1 p {
  color: white;
  font-size: 1.2rem;
  text-align: left;
}
#gewinnspiel .quizForm .col-1:last-child .soft-row .col-1 p strong {
  font-size: inherit;
}

#q6 {
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#q6 h2 {
  color: white;
  font-size: 2rem;
  font-family: "Soho Gothic W02 Regular", sans-serif;
  text-shadow: 0 0 5px rgba(57, 61, 62, 0.4);
  display: block;
  margin-bottom: 30px;
}

#popupInfo2.small .popupContent {
  width: auto;
  height: auto;
  position: relative;
  padding: 60px;
}
#popupInfo2.small .popupContent h2 {
  font-size: 1.6rem;
  color: #C70282;
  text-align: center;
  font-family: "Soho Gothic W02 Regular", sans-serif;
}

.oticon--euha-prices#more-euha .content {
  padding: 4rem;
}
.oticon--euha-prices#more-euha .content .two--col {
  grid-template-columns: 1fr 2fr;
}
.oticon--euha-prices#more-euha .content .two--col.reverse {
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "right left";
}

#winter__fixed {
  background-image: url("../img/spindarella_bg.jpg");
  background-position: top 90% center;
  background-repeat: no-repeat;
  background-size: auto 120%;
  padding-top: 80vw;
}
@media (min-width: 768px) {
  #winter__fixed {
    background-position: top 25% center;
    background-size: cover;
    background-attachment: fixed;
    padding: 2rem;
  }
}
#winter__fixed .two--col {
  /**/
  display: block;
}
@media (min-width: 768px) {
  #winter__fixed .two--col {
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 3rem;
  }
}
#winter__fixed .container {
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
}
@media (min-width: 768px) {
  #winter__fixed .container {
    max-width: 70rem;
    padding: 3rem;
  }
}
#winter__fixed h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  #winter__fixed h2 {
    font-size: 5rem;
  }
}

#auktion-intro h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  #auktion-intro h1 {
    font-size: 5rem;
  }
}

#auktionsinfo {
  background: url("../img/purple_bg.jpg") center no-repeat;
  background-size: cover;
  padding: 6rem 2rem;
}
#auktionsinfo .two--col {
  align-items: center;
}
#auktionsinfo h2 {
  color: white;
  font-size: 3rem;
}
@media (min-width: 768px) {
  #auktionsinfo h2 {
    font-size: 5rem;
  }
}
#auktionsinfo .slanted__card {
  background: white;
  padding: 3rem;
  position: relative;
  transform-origin: center;
  transform: rotate(-3deg);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
  max-width: 45rem;
}
#auktionsinfo .slanted__card:after {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  border: 2px solid black;
  pointer-events: none;
}
#auktionsinfo .slanted__card table td {
  width: 50%;
  vertical-align: top;
  padding-bottom: 1rem;
}

#bilder {
  position: relative;
}
#bilder .bild__container .content {
  padding: 6rem 2rem;
  align-items: center;
}
@media (min-width: 768px) {
  #bilder .bild__container .content {
    display: grid;
    grid-gap: 4rem;
    grid-template-areas: "left right";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
#bilder .bild__container:nth-child(even) {
  background: #f6f6f6;
}
@media (min-width: 768px) {
  #bilder .bild__container:nth-child(even) .content {
    display: grid;
    grid-gap: 4rem;
    grid-template-areas: "right left";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
#bilder .bild__container .image__container {
  grid-area: left;
}
#bilder .bild__container .image__container img {
  width: 100%;
  max-width: 60vw;
  margin: 0 auto 3rem;
}
#bilder .bild__container .text__container {
  grid-area: right;
}
#bilder .bild__container .text__container p strong {
  display: block;
}
#bilder .bild__container .text__container .gebot {
  margin-top: 2rem;
}
#bilder .bild__container .text__container .gebot .price {
  margin-top: 0.2rem;
  margin-bottom: 2rem;
  font-size: 3.5rem;
  font-family: "Soho Gothic W02 Bold";
}
#bilder .bild__container .text__container .gebot input {
  text-align: center;
  padding: 1rem 2rem;
  display: block;
  -moz-appearance: textfield;
  width: 100%;
  font-size: 1.6rem;
}
#bilder .bild__container .text__container .gebot .main--button, #bilder .bild__container .text__container .gebot #quiz--content .answer--content .questions--container .game--button, #quiz--content .answer--content .questions--container #bilder .bild__container .text__container .gebot .game--button {
  width: 100%;
  margin-top: 2rem;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#gebot .message {
  margin: 2rem 0;
  background: white;
  padding: 2rem;
  text-align: center;
}
#gebot .message.success {
  border: 2px solid #12a812;
}
#gebot .message.error {
  border: 2px solid orangered;
}
#gebot .content {
  padding: 2rem;
}
#gebot form {
  background: transparent;
  box-shadow: none;
  max-width: 100%;
  padding: 0;
}
#gebot form #meinegebote {
  margin-top: 2rem;
}
#gebot form #meinegebote input {
  display: inline;
  padding: 0;
  background: transparent;
  border: 0;
  width: auto;
}
#gebot form #meinegebote .meingebot {
  padding: 1rem 0;
  border-top: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#gebot form #meinegebote .meingebot .clear {
  cursor: pointer;
}
#gebot form #meinegebote .meingebot:last-child {
  border-bottom: 1px solid #707070;
  margin-bottom: 2rem;
}
#gebot form input {
  width: 100%;
  padding: 0.5rem 1rem;
  display: block;
  margin-bottom: 2rem;
}
#gebot form .input__group {
  display: grid;
  grid-gap: 2rem;
  grid-template-areas: "plz ort";
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
}
#gebot form .input__group input:nth-child(1) {
  grid-area: plz;
}
#gebot form .input__group input:nth-child(2) {
  grid-area: ort;
}
#gebot form .toggle--group {
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  #gebot form .toggle--group {
    align-items: center;
  }
}
#gebot form .toggle--group .toggle--switch {
  height: 2.5rem;
  width: 4rem;
  min-width: 4rem;
  background: #c3c3c3;
  border-radius: 3rem;
  margin-right: 1rem;
  position: relative;
  transition: all 150ms ease;
}
#gebot form .toggle--group .toggle--switch:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 2.1rem;
  height: 2.1rem;
  background: #3B3C3D;
  border-radius: 50%;
  transition: all 150ms ease;
}
#gebot form .toggle--group p {
  display: block;
  font-size: 1.2rem;
}
#gebot form .toggle--group p a {
  font-size: inherit;
  text-decoration: underline;
}
#gebot form .toggle--group input {
  display: none;
}
#gebot form .toggle--group input:checked ~ .toggle--switch {
  background: #C70282;
}
#gebot form .toggle--group input:checked ~ .toggle--switch:before {
  transform: translateX(1.5rem);
  background: white;
}
#gebot form small {
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #C70282;
}

#kuenstler .content {
  padding: 2rem;
}
#kuenstler .two--col {
  align-items: center;
}
#kuenstler .two--col .left--content {
  padding: 2rem;
}

#weihnachtsgruss {
  background: url("../img/purple_bg.jpg") center no-repeat;
  background-size: cover;
  text-align: center;
}
#weihnachtsgruss .content {
  padding: 2rem;
}
#weihnachtsgruss * {
  color: white;
}
#weihnachtsgruss p {
  max-width: 60rem;
  margin: 0 auto;
}
#weihnachtsgruss .iframe--wrapper {
  margin-top: 3rem;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
#weihnachtsgruss .iframe--wrapper iframe, #weihnachtsgruss .iframe--wrapper .youtube-player {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#weihnachtsgruss .iframe--wrapper iframe img, #weihnachtsgruss .iframe--wrapper .youtube-player img {
  width: 100%;
  cursor: pointer;
}

.tnb__modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 20;
  padding: 2rem;
  justify-content: center;
}
.tnb__modal.active {
  display: flex;
}
@media (min-width: 768px) and (min-height: 768px) {
  .tnb__modal {
    align-items: center;
  }
}
.tnb__modal .modal__content {
  overflow-y: auto;
  padding: 3rem;
  background: white;
  position: relative;
  width: 100%;
  max-width: 1000px;
  max-height: calc(100vh - 4rem);
  height: auto;
}
@media (min-width: 768px) {
  .tnb__modal .modal__content {
    padding: 6rem;
  }
}