.popup
  position: fixed
  width: 100vw
  height: 100vh
  background: rgba($dark-grey, 0.5)
  z-index: 9999999999999999999
  padding: 40px
  top: 0
  left: 0
  align-items: center
  justify-content: center
  display: none
  &.active
    display: flex
    


  .popupContent
    position: relative
    top: 0
   
    background: white
    max-width: 1200px
    padding: 40px
    width: 100%
    height: auto
    overflow: auto
    z-index: 999999
.close
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(255, 255, 255, 0.6), inset 0 -1px 1px rgba(0, 0, 0, 0.3)
    border: 0px solid transparent
    height: 20px
    width: 20px
    border-radius: 50%
    background: linear-gradient(to top, #c70282, #ffa0de)
    cursor: pointer
    transition: all 200ms ease
    position: absolute
    top: 10px
    right: 10px
    z-index: 999999999999999
    &:before
      content: ""
      width: 2px
      height: 10px
      position: absolute
      background: white
      left: 9px
      top: 5px
      transform: rotate(45deg)
    &:after
      content: ""
      width: 2px
      height: 10px
      position: absolute
      background: white
      left: 9px
      top: 5px
      transform: rotate(-45deg)
    &:hover
      background: linear-gradient(to top, #c70282, #c70282)
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(255, 255, 255, 0.6), inset 0 -1px 1px rgba(0, 0, 0, 0.3)
#popupAlert
  h2
    font-family: $sb
    font-size: 1.8em
    color: $magenta
    text-align: center
    max-width: 600px
    margin: 60px auto 30px

  h3
    font-family: $gb
    color: $medium-grey
    text-align: center
    margin: 0 auto 60px
  
  .soft-row
    display: flex
    margin: 0 auto 100px
    width: 100%
    justify-content: center
    align-items: flex-end
    .alertDevice
      cursor: pointer
      text-align: center
      margin: 0 40px
      .CIC
        width: 103px
        position: relative
        transition: all 300ms ease
        top: 0
        left: 5px
      .Kanal
        width: 111px
        position: relative
        transition: all 300ms ease
        top: 0
        left: -15px
      .Concha
        left: -15px
        width: 136px
        position: relative
        transition: all 300ms ease
        top: 0
      p
        margin-top: 10px
        font-family: $gb
      &:hover
        img
          top: -5px
#popupInfo
  .hinweisContent
    max-width: 600px
    margin: 30px auto
    #next
      appearance: none
      border: none
      outline: none
      height: 42px
      padding: 0 30px
      color: white
      background: lighten($blue, 10%)
      opacity: 0.25
      pointer-events: none
      margin-right: 20px
      &.active
        transition: all 300ms ease
        opacity: 1
        pointer-events: all
        cursor: pointer
        &:hover
          background: lighten($blue, 20%)
    h2
      font-size: 1.8em
      color: $magenta
      margin-bottom: 20px
    ul, ol
      margin: 20px 0
      li
        font-size: 1.1rem
        color: $medium-grey
        line-height: 1.6
    ol
      list-style-type: decimal 
      li
        margin-bottom: 20px
        margin-left: 30px
        .numbers
          display: inline-block
          width: 20px
          height: 20px
          line-height: 20px
          color: white
          border-radius: 50%
          background: $magenta
          margin-right: 10px
          text-align: center
          position: absolute
          left: -30px
        .customCheckbox
            display: inline-block
            margin-right: 10px
            &:before
              content: ""
              position: absolute
              background: $magenta
              width: 5px
              height: 2px
              transform: rotate(45deg)
              left: 2px
              bottom: 4px
            &:after
              content: ""
              position: absolute
              background: $magenta
              width: 8px
              height: 2px
              transform: rotate(-45deg)
              left: 4px
              bottom: 5px
            &.preChecked
              &:before
                background: $blue              
              &:after
                background: $blue
            &.preDefined
              &:before
                background: $medium-grey             
              &:after
                background: $medium-grey  
    .strongMagenta
      font-family: $gb
      color: $magenta
    .strongBlue
      font-family: $gb
      color: $blue
    .strongGrey
      font-family: $gb
      color: $medium-grey

    a 
      color: $magenta
    p
      font-family: $g
      color: $medium-grey
      font-size: 1.1rem
      line-height: 1.6
      display: inline
      strong
        font-family: $gb
    .popupImageFW
      max-width: 100%
      margin: 40px 0

#printPage, #printPage2
  font-size: 15px
  position: fixed
  width: 210mm
  height: 297mm
  left: 0
  top: 0px
  // transform: translate(-50%)
  background: #fff
  z-index: 99999999999999999999
  display: none
  padding: 60px 40px


  .resultHeader
    width: 100%
    color: $magenta
    font-size: 3rem
    margin-bottom: 20px
  .resultBody
    .resultList
      padding: 20px
      background: #efefef
      height: 145mm
      border: 1px solid #ececec
    .spriteImage
      width: 80mm
      height: 80mm
      margin-bottom: 40px
      overflow: hidden
      img
        position: absolute
        left: 50%
        top: 0
        height: 100%
        transform: translate(-50%)
    h3
      margin-bottom: 20px
      display: block
      font-family: $sb
      font-size: 1.3rem
      color: $medium-grey
    ul
      margin-bottom: 20px
    .resultImage
      position: absolute
      left: 340px
      top: 0
      height: 80mm
      width: 99mm
      border: 1px solid #ececec
      img
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)

#print
  position: absolute
  right: 30px
  bottom: 30px
  width: 100px
  cursor: pointer
  font-size: .925rem
  img
    width: 20px
    margin-right: 7px
    position: relative
    top: 4px

#printLogo
  width: 30mm
  position: absolute
  bottom: 0mm
  right: 10mm
  height: 30mm

#popupBauform, #popupAuswahl
  position: fixed
  width: 100vw
  height: 100vh
  left: 0
  top: 0
  background: rgba($base-font-color, 0.5)
  z-index: 9999999999999999999999
  padding: 30px
  display: none
  .popupBauformContent
    background: #fff
    margin: 0 auto
    position: absolute
    left: 50%
    top: 45%
    transform: translate(-50%,-50%)
    max-width: 640px
    width: 100%
    border-radius: 4px
    box-shadow: 1px 0 15px rgba(black, 0.4)
    padding: 60px 40px 60px 20px
    display: flex
    flex-wrap: wrap
    align-items: flex-end
    justify-content: center
    button
      padding: 10px 20px
      background: $magenta
      color: white
      border: none
      outline: none
    h2
      display: block
      width: 100%
      font-size: 1.5rem
      margin: 0 auto 20px
      font-family: $sb
      color: $magenta
      text-align: center
    .choose
      text-align: center!important
      min-width: 20%
      cursor: pointer

      img
        display: block
        
        margin: 0 auto 10px
.hidden
  display: none!important


@media print
  .mainContent
    display: none
  #printPage, #printPage2
    display: block!important

.listeHinweis
  margin-top: 0!important
  li
    margin-bottom: 0!important
    

    