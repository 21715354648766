header

	background: url('../img/ot_more_keyvisual.jpg') center no-repeat

	display: flex
	align-items: stretch
	justify-content: center
	padding: 2rem
	position: relative
	background-size: cover
	overflow: hidden

	@media (min-width: $mobile)
		min-height: 100vh
		background-attachment: fixed

	&.home
		
		background-image: url('../img/ot_more_keyvisual-1.jpg')
		background-repeat: no-repeat
		background-position: right 35% bottom 45%
		background-size: cover
		@media (min-width: $mobile)
			background-position: right 35% bottom 0
		#unterschrift
			max-width: 400px
			width: 60vw
			margin-top: 2rem
			margin-bottom: 30vh
			@media (min-width: $mobile)
				margin-top: 4rem
				margin-bottom: 0
	
	&.winter
		background-image: url('../img/ot_more_keyvisual-winter.jpg')
		background-repeat: no-repeat
		background-position: center bottom 45%
		background-size: cover
		justify-content: center
		flex-direction: column
		*
			color: white
		@media (min-width: $mobile)
			min-height: auto
			background-position: left 10% bottom 0
			background-size: cover
			background-attachment: initial
		@media (min-width: $tablet)
			min-height: 100vh
			background-attachment: fixed
			background-position: center bottom 0
		.top__content
			width: 100%
			max-width: 140rem
			position: absolute
			top: 0
			padding: 2rem
			left: 50%
			transform: translateX(-50%)
			#logo-more
				width: 20rem
				@media (min-width: $tablet)
					width: 26rem
		.content
			min-height: 0
			.h2
				margin-bottom: 3rem
			p
				font-size: 1.6rem
				max-width: 50vw
				@media (min-width: $tablet)
					max-width: 40rem
					font-size: 3rem
				font-weight: 400
	
	&.euha
		background: url('../img/ot_more_keyvisual-2.jpg')
		background-repeat: no-repeat
		background-position: right 35% center
		background-size: cover
		@media (min-width: $mobile)
			background-position: right 35% center
		h1
			max-width: 50rem
	.content
		position: relative
		min-height: calc(100vh - 4rem)
		#logo-more
			position: absolute
			left: 0
			top: 0
			max-width: 40vw
		#logo
			position: absolute
			right: 0
			bottom: 0
			max-width: 25vw
		#down
			position: absolute
			left: 50%
			bottom: 0
			transform: translate(-50%,0)
			animation: upDown forwards infinite 3s ease
		h1
			margin-top: 6rem
			color: white
			text-shadow: 0 0 0.6rem rgba(black, 0.2)
			font-size: $xl
			line-height: 1.2
			margin-bottom: 1rem
			font-family: $regular
			@media (min-width: $tablet)
				margin-top: 8rem
				font-size: $xxl
		h2
			font-size: $l
			color: white
			font-family: $regular
			text-shadow: 0 0 0.6rem rgba(black, 0.2)
			max-width: 50rem
			line-height: 1.4
			@media (min-width: $tablet)
				font-size: $xl

		span.h1
			margin-top: 6rem
			color: white
			text-shadow: 0 0 0.6rem rgba(black, 0.2)
			font-size: $xl
			line-height: 1.2
			margin-bottom: 1rem
			font-family: $regular
			display: block
			@media (min-width: $tablet)
				margin-top: 8rem
				font-size: $xxl
		span.h2
			font-size: $xl
			color: white
			font-family: $regular
			text-shadow: 0 0 0.6rem rgba(black, 0.2)
			line-height: 1.2
			display: block
			@media (min-width: $tablet)
				font-size: $xxl

	&.testimonial
		background: unset
		background-color: #410032
		min-height: 0
		// max-height: 100rem
		text-align: center

		h1
			text-align: center
		h2
			text-align: center
			margin: auto
		.main--button
			// margin: 0 auto
			margin-top: 2rem
		.content
			min-height: 0
			padding-bottom: 10rem
			padding-top: 5rem


			#down
				img
					width: 24px
					height: 24px

	.menu-btn
		display: none

	@media (min-width: 65rem)
		.menu-icon
			display: none

	@media (max-width: 65rem)

		.header
			z-index: 3
			background-color: rgba(255,255,255,.9)
			box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1)
			position: absolute
			right: 0
			top: 0

			.menu
				clear: both
				max-height: 0
				transition: max-height .2s ease-out
				overflow: hidden

				div
					display: block
					text-align: right
					margin-top: 1.5rem

			.menu-icon
				cursor: pointer
				display: inline-block
				position: absolute
				right: 5px
				top: 15px
				user-select: none
				height: 30px

				.navicon
					background: #ffffff
					display: block
					height: 2px
					position: relative
					transition: background .2s ease-out
					width: 21px

					&:before,
					&:after
						background: #ffffff
						content: ''
						display: block
						height: 100%
						position: absolute
						transition: all .2s ease-out
						width: 100%

					&:before
						top: 7px

					&:after
						top: -7px

			.menu-btn:checked ~ .menu
				max-height: 240px
				padding: 4rem 2rem 4rem 6rem
				width: 100%

			.menu-btn:checked ~ .menu-icon .navicon
				background: transparent

			.menu-btn:checked ~ .menu-icon .navicon:before
				transform: rotate(-45deg)
				background: $magenta

			.menu-btn:checked ~ .menu-icon .navicon:after
				transform: rotate(45deg)
				background: $magenta

			.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
			.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after
				top: 0



