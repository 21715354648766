#winter__fixed
	background-image: url('../img/spindarella_bg.jpg') 
	background-position: top 90% center 
	background-repeat: no-repeat
	background-size: auto 120%
	padding-top: 80vw
	@include tablet
		background-position: top 25% center 
		background-size: cover
		background-attachment: fixed
		padding: 2rem
	.two--col
		/**/
		display: block
		@include tablet
			display: grid
			grid-template-areas: "left right"
			grid-template-columns: 1fr 1fr
			grid-template-rows: auto
			grid-gap: 3rem

	.container
		background: rgba(white, 0.8)
		padding: 2rem
		@media (min-width: $tablet)
			max-width: 70rem
			padding: 3rem
	h2
		font-size: 3rem
		margin-bottom: 2rem
		@media (min-width: $tablet)
			font-size: 5rem
#auktion-intro
	h1
		font-size: 3rem
		margin-bottom: 2rem
		@include tablet
			font-size: 5rem
#auktionsinfo
	background: url('../img/purple_bg.jpg') center no-repeat
	background-size: cover
	padding: 6rem 2rem
	.two--col
		align-items: center
	h2
		color: white
		font-size: 3rem
		@include tablet
			font-size: 5rem
	.slanted__card
		background: white
		padding: 3rem
		position: relative
		transform-origin: center
		transform: rotate(-3deg)
		box-shadow: 0 0 1rem rgba(black, 0.15)
		max-width: 45rem
		&:after
			content: ""
			position: absolute
			left: 0.5rem
			top: 0.5rem
			right: 0.5rem
			bottom: 0.5rem
			border: 2px solid black
			pointer-events: none
		table
			td
				width: 50%
				vertical-align: top
				padding-bottom: 1rem

#bilder
	position: relative
	.bild__container
		.content
			padding: 6rem 2rem
			@include tablet
				@include grid-areas('left right', 1fr 1fr, auto, 4rem)
			align-items: center
		&:nth-child(even)
			background: #f6f6f6
			.content
				@include tablet
					@include grid-areas('right left', 1fr 1fr, auto, 4rem)
		.image__container
			grid-area: left
			@include tablet
				// max-width: 50%
			img
				width: 100%
				max-width: 60vw
				margin: 0 auto 3rem
		.text__container
			grid-area: right
			p
				strong
					display: block
			.gebot
				margin-top: 2rem
				.price
					margin-top: 0.2rem
					margin-bottom: 2rem
					font-size: 3.5rem	
					font-family: $gb
				input
					text-align: center
					padding: 1rem 2rem
					display: block
					-moz-appearance: textfield
					width: 100%
					font-size: 1.6rem
				.main--button
					width: 100%
					margin-top: 2rem
					text-align: center
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

#gebot
	.message
		margin: 2rem 0
		background: white
		padding: 2rem
		text-align: center
		&.success
			border: 2px solid #12a812
		&.error
			border: 2px solid orangered
	.content
		padding: 2rem
	form
		background: transparent
		box-shadow: none
		max-width: 100%
		padding: 0
		#meinegebote
			margin-top: 2rem
			input
				display: inline
				padding: 0
				background: transparent
				border: 0
				width: auto
			.meingebot
				padding: 1rem 0
				border-top: 1px solid $light-grey
				display: flex
				align-items: center
				justify-content: space-between
				.clear
					cursor: pointer
				&:last-child
					border-bottom: 1px solid $light-grey
					margin-bottom: 2rem

		input
			width: 100%
			padding: .5rem 1rem
			display: block
			margin-bottom: 2rem
		.input__group
			@include grid-areas('plz ort', 1fr 2fr, auto, 2rem)
			input
				&:nth-child(1)
					grid-area: plz
				&:nth-child(2)
					grid-area: ort
		.toggle--group
			display: flex
			cursor: pointer
			@media (min-width: $tablet)
				align-items: center
			margin-bottom: 20px
			.toggle--switch
				height: 2.5rem
				width: 4rem
				min-width: 4rem
				background: #c3c3c3
				border-radius: 3rem
				margin-right: 1rem
				position: relative
				transition: all 150ms ease
				&:before
					content: ""
					position: absolute
					left: 2px
					top: 2px
					width: 2.1rem
					height: 2.1rem
					background: #3B3C3D
					border-radius: 50%
					transition: all 150ms ease
			p
				display: block
				font-size: 1.2rem
				a
					font-size: inherit
					text-decoration: underline

			input
				display: none
				&:checked
					~ .toggle--switch
						background: #C70282
						&:before
							transform: translateX(1.5rem)
							background: white
		small
			margin: 0 0 10px
			font-size: 1.2rem
			color: #C70282

#kuenstler
	.content
		padding: 2rem
	.two--col
		align-items: center
		.left--content
			padding: 2rem
#weihnachtsgruss
	background: url('../img/purple_bg.jpg') center no-repeat
	background-size: cover
	text-align: center
	.content
		padding: 2rem
	*
		color: white
	p
		max-width: 60rem
		margin: 0 auto
	.iframe--wrapper
		margin-top: 3rem
		width: 100%
		height: 0
		padding-bottom: 56.25%
		position: relative
		iframe, .youtube-player
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			img
				width: 100%
				cursor: pointer

.tnb__modal
	display: none
	position: fixed
	left: 0
	top: 0
	width: 100%
	height: 100%
	background: rgba(black, 0.9)
	z-index: 20
	padding: 2rem
	&.active
		display: flex
	@media (min-width: $tablet) and (min-height: $tablet)
		align-items: center
	justify-content: center
	.modal__content
		overflow-y: auto
		padding: 3rem
		@include tablet
			padding: 6rem
		background: white
		position: relative
		width: 100%
		max-width: $desktop
		max-height: calc(100vh - 4rem)
		height: auto
