#navigation
  @media (min-width: 65rem)
    position: absolute
    right: 0
    top: 12px
    display: flex

  .list
    @media (min-width: 65rem)
      padding: 0 2rem
    &:first-child
      @media (min-width: 65rem)
        padding-left: 0
    &:last-child
      @media (min-width: 65rem)
        padding-right: 0
    .hover 
      text-align: right
      padding: 0
      margin: 0 auto
      transition: $animate
      position: relative
      color: #000000
      font-size: 1.6rem
      text-transform: uppercase
      @media (min-width: 65rem)
        text-shadow: 0 0 0.6rem rgba(black, 0.2)
        color: #fff
        text-align: center
      &:before,
      &:after 
        content: ""
        position: absolute
        bottom: -7px
        width: 0
        height: 2px
        margin: 2px 0 0
        transition: $animate
        transition-duration: 0.45s
        opacity: 0
        background-color: darken($magenta,5%)

      &:before 
        left: 50%
      
      &:after 
        right: 50%
    .hover.active
        &:before,
        &:after
          opacity: 1
          width: 50%
    &:hover
      cursor: pointer
      .hover
        &:before,
        &:after
          opacity: 1
          width: 50%